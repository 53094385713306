import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "@services/api";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import Loader from "../../components/utils/Loader";

const PaiementLinks = () => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [paiementLinks, setPaiementLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGocardlessPaymentLinks = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/gocardless/billing-requests/${organization._id}`);
      setPaiementLinks(data.billing_requests);
    } catch (err) {
      toast.error(t("toast.error.fetching_payment_links"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organization?.gocardlessAccessToken) getGocardlessPaymentLinks();
  }, []);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_payment_links);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("analytics.tab_payment_links")}</h1>
            </div>
          </div>
          <div className="flex flex-col space-y-5 mt-2">
            <Table paiementLinks={paiementLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ paiementLinks }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border pb-24">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500 uppercase">
              {t("status")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("description")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500 uppercase">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500 uppercase">
              {t("currency")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {paiementLinks.map((link, index) => (
            <tr key={index}>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <Status status={link.status} />
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{link.payment_request.description}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{link.payment_request.amount / 100}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{link.payment_request.currency}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  let dotColor = "bg-gray-400";
  if (status === "fulfilled") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  }
  if (status === "fullfilling") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-600";
    dotColor = "bg-yellow-400";
  }
  if (status === "ready_to_fulfil") {
    bgColor = "bg-pink-100";
    textColor = "text-pink-600";
    dotColor = "bg-pink-400";
  }
  if (status === "pending") {
    bgColor = "bg-purple-100";
    textColor = "text-purple-600";
    dotColor = "bg-purple-400";
  }
  if (status === "cancelled") {
    bgColor = "bg-red-100";
    textColor = "text-red-600";
    dotColor = "bg-red-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
      <span>{t(`payment.status.${status}`)}</span>
    </span>
  );
};

export default PaiementLinks;
