import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import html2pdf from "html2pdf.js";
import { Link, useNavigate } from "react-router-dom";

import EditClient from "../clients/editClient";
import api from "@services/api";
import { currencyToStr, getClientName } from "@utils";
import { useSelector } from "react-redux";
import { MdKeyboardArrowUp, MdOutlineEdit } from "react-icons/md";
import { HiOutlineDocumentMagnifyingGlass, HiOutlineLockClosed } from "react-icons/hi2";
import { IoSaveOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import FilesInput from "../../components/utils/FilesInput";
import { useTranslation } from "react-i18next";
import { apiURL } from "@config";
import { Mixpanel } from "@services/mixpanel";
import CreateProduct from "@components/modals/createProduct";
import CreateClient from "@components/modals/createClient";
import UpdateOrganization from "@components/modals/updateOrganization";
import { getTranslation } from "../../lang/invoice-translation";
import InvoiceParameter from "./components/invoiceParameter";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Editor = ({ invoice, onFinish }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(invoice);
  const print = new URLSearchParams(location.search).get("print");
  const [isPrinting, setIsPrinting] = useState(print ?? false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const organization = useSelector((state) => state.Auth.organization);
  const user = useSelector((state) => state.Auth.user);
  const invoiceRef = useRef();
  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_invoice_editor);
  }, []);

  const updateInvoice = async () => {
    let response;
    try {
      const invoiceProducts = values.InvoiceProducts.filter((p) => p.ProductId);
      response = await api.put(`/invoice/${values._id}`, {
        ...values,
        InvoiceProducts: invoiceProducts,
        messageBody: generateMessageBody(values, user),
        language: values.language,
      });
      if (!response.ok) throw response.code;
      toast.success(t("toast.sucess.invoice_updated"));
    } catch (error) {
      console.error("Error updating invoice", error);
      toast.error(t("toast.error.invoice_updating"));
    } finally {
      return response;
    }
  };

  async function get() {
    try {
      const { data: invoiceData } = await api.get(`/invoice/${values?._id}`);
      setValues(invoiceData);
    } catch (error) {
      console.error(t("toast.error"), error);
      toast.error(error.code);
    }
  }

  async function generatePDF() {
    try {
      setLoadingPreview(true);
      await updateInvoice();
      setIsPrinting(true);
      invoiceRef.current.classList.add("printing");
      await new Promise((resolve) => setTimeout(resolve, 200));
      const res = await api.post(`/invoice/${values._id}/pdf`);
      if (!res.ok) throw res.code;
      invoiceRef.current.classList.remove("printing");
      setIsPrinting(false);
      return res.data;
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setLoadingPreview(false);
    }
  }

  async function handleFinish() {
    await generatePDF();
    const generateInvoice = await api.put(`/invoice/finish/${values._id}`);
    if (!generateInvoice.ok) throw generateInvoice;
    toast.success(t("toast.sucess.invoice_generated"));
  }

  function generateMessageBody(invoice) {
    return t("invoices.editor.message_body_default", {
      organization: invoice.Organization?.name,
      reference: invoice.reference,
      amount: invoice.totalTaxIncluded,
      currency: currencyToStr(invoice.currency ?? "EUR"),
      dueAt: new Date(invoice.dueAt).toLocaleDateString(),
      sender: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
    });
  }

  // at each change of invoice, we recompute taxes & totals for each product and the invoice
  function handleInvoiceChange(invoice) {
    // compute taxValue & totalTaxIncluded for each product with discount
    invoice.InvoiceProducts = invoice.InvoiceProducts.map((product) => {
      const taxRate = invoice.TaxId ? organization.Taxes.find((tax) => tax._id === invoice.TaxId)?.value : organization.Taxes.find((tax) => tax._id === product.TaxId)?.value;
      const baseTaxValue = parseFloat((product.price * product.quantity * taxRate) / 100).toFixed(2);
      const taxValue = parseFloat(baseTaxValue - (invoice.discount / 100) * baseTaxValue).toFixed(2);
      const subTotal = parseFloat(product.price * product.quantity).toFixed(2);
      return {
        ...product,
        TaxId: invoice.TaxId || product.TaxId,
        taxValue,
        subTotal,
        totalTaxIncluded: parseFloat(subTotal) + parseFloat(taxValue),
      };
    });

    const subTotal = parseFloat(invoice.InvoiceProducts.reduce((acc, product) => acc + parseFloat(product.subTotal), 0)).toFixed(2);
    const totalTaxExcluded = parseFloat(subTotal - (invoice.discount / 100) * subTotal);
    const taxValue = parseFloat(invoice.InvoiceProducts.reduce((acc, product) => acc + parseFloat(product.taxValue), 0)).toFixed(2);
    const newInvoice = {
      ...invoice,
      subTotal,
      taxValue,
      totalTaxExcluded,
      totalTaxIncluded: parseFloat(parseFloat(totalTaxExcluded) + parseFloat(taxValue)).toFixed(2),
    };

    setValues({ ...newInvoice });
  }

  if (!values) return <Loader />;

  return (
    <div className="mx-auto bg-white">
      <PreviewModal className="z-20" isOpen={previewOpen} invoice={values} onClose={() => setPreviewOpen(false)} />
      <div className="flex">
        <div className="justify-center w-full">
          <div className="py-1 flex justify-between items-center sticky top-0 z-10 bg-white px-8 border-b border-gray-200">
            <div className="flex items-center gap-10">
              <div className="flex flex-col">
                <div className="text-sm font-semibold">{t("status")}</div>
                <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600`}>
                  <div className={`h-2 w-2 rounded-full bg-gray-400`} />
                  {t("invoices.status.DRAFT")}
                </span>
              </div>
              <textarea
                type="text"
                value={values.memo ?? ""}
                onChange={(e) => setValues({ ...values, memo: e.target.value })}
                className="border-[1px] border-gray-200 placeholder:text-gray-300 rounded-lg px-3"
                placeholder={t("memo_note.to_enter.placeholder")}
              />
              <FilesInput
                values={values.memoFiles}
                onChange={(e) => setValues({ ...values, memoFiles: e.target.values ? e.target.values.flat() : [] })}
                name={values._id}
                folder={`/invoice/${values._id}/memoFiles`}
              />
            </div>
            <div className="flex gap-2 items-center">
              <button className="transparent-btn" onClick={() => setIsSettingsOpen((p) => !p)}>
                <IoSettingsOutline className="text-gray-800 text-lg mr-2" />
                {t("settings")}
              </button>
              <button
                className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black"
                onClick={async () => {
                  const data = await generatePDF();
                  setValues({ ...values, ...data });
                  setPreviewOpen(true);
                }}>
                <HiOutlineDocumentMagnifyingGlass className="text-base text-gray-500" />
                <span className="">{t("preview")}</span>
              </button>
              <button
                className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black"
                onClick={async () => {
                  await updateInvoice();
                  Mixpanel.track(MIXPANEL_EVENTS.btn_save_invoice);
                  get();
                }}>
                <IoSaveOutline className="text-base text-gray-500" />
                <span className="">{t("save")}</span>
              </button>
              <button
                onClick={async () => {
                  try {
                    if (values.InvoiceProducts.some((product) => !product.TaxId)) return toast.error(t("toast.error.invoice.tax_error"));
                    const responsePutInvoice = await updateInvoice();
                    if (!responsePutInvoice.ok) throw new Error("Error updating invoice");
                    if (!values.ClientId) return toast.error(t("toast.error.invoice.client_missing"));
                    if (!values.InvoiceProducts.length) return toast.error(t("toast.error.invoice.product_missing"));
                    if (values.InvoiceProducts.some((product) => !product.ProductId)) return toast.error(t("toast.error.invoice.line_error"));

                    if (!window.confirm(t("invoices.editor.confirm_finish"))) return;
                    await handleFinish();
                    Mixpanel.track(MIXPANEL_EVENTS.btn_finished_invoice);
                    onFinish();
                  } catch (error) {
                    console.error(t("toast.error.invoice_generating"), error);
                    toast.error(error.code);
                  }
                }}
                className="flex items-center gap-2 py-2 px-4 bg-blue-500 hover:bg-blue-400 text-white tracking-wide text-sm border border-blue-500 hover:border-transparent rounded">
                <HiOutlineLockClosed className="text-base text-white" />
                <span className="">{t("invoices.editor.finish")}</span>
              </button>
            </div>
          </div>
          <div className="px-8 py-4">
            <div className="shadow-xl w-full border-[1px]">
              <div className={`flex justify-center gap-1`}>
                <div className="bg-white px-6 py-10 text-black w-full">
                  <Builder
                    loadingPreview={loadingPreview}
                    invoiceRef={invoiceRef}
                    isPrinting={isPrinting}
                    invoice={values}
                    taxOptions={organization.Taxes}
                    setInvoice={handleInvoiceChange}
                    updateAndGetInvoice={async () => {
                      await updateInvoice();
                      get();
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`transition-all fixed inset-0 bg-black/20 z-50 ${isSettingsOpen ? "translate-x-0" : "translate-x-full"} duration-0`}
              onClick={() => setIsSettingsOpen(false)}
            />
            <div
              onClick={(e) => e.stopPropagation()}
              className={`fixed top-0 right-0 h-full w-1/4 bg-white text-black shadow-lg transform transition-transform duration-300 z-50 ${
                isSettingsOpen ? "translate-x-0" : "translate-x-full"
              }`}>
              <div className="relative p-4">
                <button className="absolute top-2 right-2 transparent-btn" onClick={() => setIsSettingsOpen(false)}>
                  <IoClose />
                </button>
                <InvoiceParameter invoice={values} setInvoice={handleInvoiceChange} handleSave={updateInvoice} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Builder = ({ loadingPreview, invoiceRef, isPrinting, invoice, setInvoice, updateAndGetInvoice, taxOptions }) => {
  const { t } = useTranslation();

  const handleTermsChange = (e) => {
    setInvoice({ ...invoice, termsBody: e.target.value });
  };

  return (
    <div className="flex flex-col justify-center gap-1">
      <div className="relative bg-white px-6 py-10 text-black">
        {loadingPreview && (
          <div className="absolute top-0 bottom-0 left-0 right-0 backdrop-blur grid place-content-center">
            <Loader />
          </div>
        )}
        <div id="invoiceToGenerate" ref={invoiceRef} className="px-4 pt-4">
          <Header invoice={invoice} setInvoice={setInvoice} isPrinting={isPrinting} updateAndGetInvoice={updateAndGetInvoice} />
          <span className="font-bold text-xl">Facture {invoice.reference}</span>
          <InvoiceDates invoice={invoice} setInvoice={setInvoice} isPrinting={isPrinting} />
          <AmountTable invoice={invoice} setInvoice={setInvoice} isPrinting={isPrinting} taxOptions={taxOptions} />
          <SummaryTable invoice={invoice} setInvoice={setInvoice} isPrinting={isPrinting} taxOptions={taxOptions} />
          <div className="flex flex-col mt-2">
            <h3 className="font-semibold text-xs">{getTranslation("invoices_editor_terms_conditions", invoice.language)}</h3>
            {isPrinting ? (
              <p className="text-xs">{invoice.termsBody || getTranslation("invoices_editor_terms_conditions_desc", invoice.language)}</p>
            ) : (
              <textarea
                className="text-xs border border-gray-100 focus:outline-none  focus:border-gray-500 p-1 rounded resize-none"
                value={invoice.termsBody || getTranslation("invoices_editor_terms_conditions_desc", invoice.language)}
                onChange={handleTermsChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function getBase64file(url) {
  return new Promise((resolve, reject) => {
    let imagePath = "";

    if (url.startsWith("https://finotor.cellar-c2.services.clever-cloud.com")) {
      imagePath = url.replace("https://finotor.cellar-c2.services.clever-cloud.com", "");
      url = apiURL + "/proxy" + imagePath;
    }

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response error ${res.status}`);
        }
        return res.blob();
      })
      .then((image) => {
        var reader = new FileReader();
        reader.onload = function () {
          var dataURL = this.result;
          resolve(dataURL);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(image);
      })
      .catch((error) => {
        console.error("Error getting logo", error);
        reject(error);
      });
  });
}

const Header = ({ invoice, isPrinting, setInvoice, updateAndGetInvoice }) => {
  // html2pdf can't download images from other origins
  // We host our images on S3
  // So we need to download them first
  const { t } = useTranslation();
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [openOrganizationParams, setOpenOrganizationParam] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const organization = useSelector((state) => state.Auth.organization);

  async function getAllClients() {
    const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization._id });
    setClientOptions(data.clients);
  }

  useEffect(() => {
    if (!organization) return;
    getAllClients();
  }, [organization]);

  useEffect(() => {
    if (!invoice.Organization?.logo) return setOrganizationLogo(null);
    getBase64file(invoice.Organization?.logo).then((logo) => setOrganizationLogo(logo));
  }, [invoice.Organization?.logo]);

  const missingInformation =
    !invoice.Organization?.iban ||
    !invoice.Organization?.vat ||
    !invoice.Organization?.registrationNumber ||
    !invoice.Organization?.email ||
    !invoice.Organization?.billingAddressStreet ||
    !invoice.Organization?.billingAddressCity ||
    !invoice.Organization?.billingAddressZipCode ||
    !invoice.Organization?.billingAddressCountry;

  return (
    <div>
      <header className="grid justify-center grid-cols-2 gap-x-10">
        <div className="col-start-1 flex flex-col gap-y-0 gap-3 items-start justify-start h-full">
          {!isPrinting && (
            <Combobox
              options={clientOptions}
              value={clientOptions.find((client) => client._id === invoice.ClientId)}
              onChange={(e) => {
                setInvoice({ ...invoice, ClientId: e?._id || null, Client: e || null, recipients: [...(e?.emails ?? [])] });
              }}
              listHeader={
                <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClient(true)}>
                  {t("clients.create_a_client")} <span>+</span>
                </button>
              }
              placeholder={t("client")}
              getLabel={(client) => clientorSupplierOption(client)}
            />
          )}

          <div className="flex gap-3 items-center mb-2">
            <h1 className="text-black font-semibold">{getClientName(invoice.Client)}</h1>
          </div>
          {invoice.Client?.billingAddressStreet && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">{t("address")}</span>
              <span className="">
                {[invoice.Client?.billingAddressStreet, invoice.Client?.billingAddressZipCode, invoice.Client?.billingAddressCity, invoice.Client?.billingAddressCountry]
                  .filter((address) => address)
                  .join(", ")}
              </span>
            </p>
          )}
          {invoice.Client?.emails && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">{t("email")}</span>
              <span className="">{invoice.Client?.emails[0]}</span>
            </p>
          )}
          {invoice.Client?.registrationNumber && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">{t("registration_number")}</span>
              <span className="">{invoice.Client?.registrationNumber}</span>
            </p>
          )}
          {invoice.Client?.vatNumber && (
            <p className="text-sm py-1">
              <span className="font-semibold mr-2">{t("vat_number")}</span>
              <span className="">{invoice.Client?.vatNumber}</span>
            </p>
          )}
        </div>
        <div className="col-start-2 h-full text-right">
          <div className="flex flex-col gap-3 items-end mb-2 ">
            <div className="cursor-pointer" onClick={() => setOpenOrganizationParam(true)}>
              {organizationLogo ? (
                <img alt={invoice.Organization?.name} className="max-h-[60px] aspect-auto " src={organizationLogo} />
              ) : (
                <div className="h-24 w-24 flex items-center justify-center rounded-xl bg-blue-500 text-white font-bold cursor-pointer text-4xl">
                  {invoice.Organization?.name.charAt(0)}
                </div>
              )}
            </div>

            <h1 className="text-black font-semibold flex-1">{invoice.Organization?.name}</h1>
          </div>

          <p className="text-sm py-1 text-right">
            <span className="text-gray-500">{getTranslation("address", invoice.language)} : </span>
            <span className="">
              {[
                invoice.Organization?.billingAddressStreet,
                invoice.Organization?.billingAddressZipCode,
                invoice.Organization?.billingAddressCity,
                invoice.Organization?.billingAddressCountry,
              ]
                .filter((address) => address)
                .join(", ")}
            </span>
          </p>
          <p className="text-sm py-1 text-right">
            <span className="text-gray-500"> {t("email")} : </span>
            <span className="">{invoice.Organization?.email}</span>
          </p>
          <p className="text-sm py-1 text-right">
            <span className="text-gray-500">{getTranslation("registration_number", invoice.language)} : </span>
            <span className="">{invoice.Organization?.registrationNumber}</span>
          </p>
          <p className="text-sm py-1 text-right">
            <span className="text-gray-500"> {getTranslation("vat_number", invoice.language)} : </span>
            <span className="">{invoice.Organization?.vat}</span>
          </p>
          <p className="text-sm py-1 text-right">
            <span className="text-gray-500">{t("iban")} : </span>
            <span className="">{invoice.Organization?.iban}</span>
          </p>
          {missingInformation && (
            <button onClick={() => setOpenOrganizationParam(true)} className={isPrinting ? " hidden" : "text-right btn-secondary btn-small mt-2 "}>
              {t("invoices.modify_information")}
            </button>
          )}
        </div>
      </header>
      <div className="border-t border-t-gray-300 mt-10 mb-5"></div>
      <CreateClient
        open={showCreateClient}
        onCreated={(data) => {
          getAllClients();
          setSelectedClient(data);
          setInvoice({ ...invoice, ClientId: data._id });
        }}
        onClose={() => setShowCreateClient(false)}
      />
      <EditClient
        client={selectedClient}
        setClient={() => {
          setSelectedClient(null);
        }}
        onSave={(newClient) => {
          setInvoice({ ...invoice, ClientId: newClient?._id || null, Client: newClient || null, recipients: [...(newClient?.emails ?? [])] });
        }}
      />
      <UpdateOrganization organization={invoice.Organization} open={openOrganizationParams} onClose={() => setOpenOrganizationParam(false)} onSave={updateAndGetInvoice} />
    </div>
  );
};

const InvoiceDates = ({ invoice, setInvoice, isPrinting }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-20 mt-3 mb-5">
      <div className="flex flex-col">
        <span className="text-gray-500"> {t("sent_at")} </span>
        {isPrinting ? (
          new Date(invoice.sentAt).toLocaleDateString()
        ) : (
          <input
            onChange={(evt) => setInvoice({ ...invoice, sentAt: evt.target.value ? new Date(evt.target.value) : new Date() })}
            onFocus={(evt) => evt.target.showPicker()}
            type="date"
            value={new Date(invoice.sentAt).toISOString().slice(0, 10)}
            placeholder="dd-mm-yyyy"
            className="border p-4 rounded-md"
          />
        )}
      </div>
      <div className="flex flex-col">
        <span className="text-gray-500"> {getTranslation("due_date", invoice.language)} </span>
        {isPrinting ? (
          new Date(invoice.dueAt).toLocaleDateString()
        ) : (
          <input
            onChange={(evt) => setInvoice({ ...invoice, dueAt: evt.target.value ? new Date(evt.target.value) : new Date() })}
            onFocus={(evt) => evt.target.showPicker()}
            type="date"
            value={new Date(invoice.dueAt).toISOString().slice(0, 10)}
            placeholder="dd-mm-yyyy"
            className="border p-4 rounded-md"
          />
        )}
      </div>
    </div>
  );
};

const AmountTable = ({ invoice, setInvoice, isPrinting, taxOptions }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const PRODUCTS_PER_PAGE = 22;
  const FIRST_BREAKPOINT_THRESHOLD_LOWER = 11;
  const FIRST_BREAKPOINT_THRESHOLD_UPPER = 16;
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [createdProduct, setCreatedProduct] = useState(null);
  const [createdProductIndex, setCreatedProductIndex] = useState(null);
  const organization = useSelector((state) => state.Auth.organization);
  const navigate = useNavigate();

  const getProducts = async () => {
    try {
      const req = await api.post("/product/search", { OrganizationId: organization._id, per_page: 1000 });
      if (!req.ok) throw req;
      if (!req.data.total) throw new Error("No product found");
      setProducts(req.data.products);
    } catch (e) {
      console.error(t("toast.error"), e);
    }
  };

  useEffect(() => {
    if (!organization) return;
    getProducts();
  }, [organization]);

  useEffect(() => {
    if (createdProduct && createdProductIndex >= 0) {
      handleProductChange(createdProductIndex, {
        ProductId: createdProduct._id,
        InvoiceId: invoice._id,
        quantity: createdProduct.quantity,
        unit: createdProduct.unit,
        price: createdProduct.price,
        currency: createdProduct.currency,
        total: createdProduct.total,
        name: createdProduct.name,
        description: createdProduct.description,
        category: createdProduct.category,
        discount: createdProduct.discount,
        taxRate: createdProduct.taxRate,
        taxValue: 0,
      });
    }
  }, [createdProduct]);

  const handleProductChange = (index, invoiceProduct) => {
    const newProducts = [...invoice.InvoiceProducts];
    newProducts[index] = invoiceProduct;
    setInvoice({
      ...invoice,
      InvoiceProducts: newProducts,
    });
  };

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  const addProduct = () => {
    setInvoice({
      ...invoice,
      InvoiceProducts: [
        ...invoice.InvoiceProducts,
        {
          ProductId: null,
          InvoiceId: invoice._id,
          quantity: null,
          unit: null,
          price: null,
          currency: null,
          total: null,
          description: null,
          category: null,
          discount: null,
          taxType: null,
          taxRate: 0,
          TaxId: null,
          taxValue: 0,
          totalTaxIncluded: 0,
          totalTaxExcluded: 0,
          subTotal: 0,
        },
      ],
    });
  };

  const removeProduct = (index) => {
    setInvoice({
      ...invoice,
      InvoiceProducts: [...invoice.InvoiceProducts.slice(0, index), ...invoice.InvoiceProducts.slice(index + 1)],
    });
  };

  return (
    <div className="flex flex-col mt-3">
      <table className="w-full">
        <thead>
          <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold py-2.5">
            <th className="p-2.5 pl-2 w-5/12"> {getTranslation("name", invoice.language)} </th>
            <th className="py-2 pl-2 text-right"> {getTranslation("quantity", invoice.language)} </th>
            <th className="py-2 pl-2 text-right whitespace-nowrap"> {getTranslation("unit_price", invoice.language)} </th>
            <th className="py-2 pl-2 text-right whitespace-nowrap">{getTranslation("tax", invoice.language)} %</th>
            <th className="py-2 pl-2 text-right whitespace-nowrap"> {getTranslation("tax", invoice.language)} €</th>
            <th className="py-2 pl-2 pr-4 text-right whitespace-nowrap"> {getTranslation("products_edit_price_ht", invoice.language)} </th>
          </tr>
        </thead>
        <tbody>
          {invoice.InvoiceProducts.map((invoiceProduct, index) => {
            const key = invoiceProduct.ProductId ? `${invoiceProduct.ProductId}-${invoiceProduct.InvoiceId}` : index;
            const current = index + 1;

            const nbProducts = invoice.InvoiceProducts.length;
            const firstBreakPoint =
              nbProducts > FIRST_BREAKPOINT_THRESHOLD_LOWER + 1 && nbProducts < FIRST_BREAKPOINT_THRESHOLD_UPPER + 1 ? nbProducts : FIRST_BREAKPOINT_THRESHOLD_UPPER;

            let breakPage = current % (PRODUCTS_PER_PAGE + firstBreakPoint) === 0;
            const firstPage = current === firstBreakPoint;

            if (firstPage) {
              breakPage = current % firstBreakPoint === 0;
            }

            return (
              <React.Fragment key={key}>
                <tr className=" text-sm text-black text-left pb-10 break-inside-avoid align-top [&>td]:pt-5" key={key}>
                  <td className="pl-3 pt-5 whitespace-nowrap w-64">
                    <div className="flex flex-col h-full gap-2">
                      <span className="whitespace-pre w-full">
                        {isPrinting ? (
                          invoiceProduct.name
                        ) : (
                          <Combobox
                            options={products.filter((product) => !invoice.InvoiceProducts.find((invoiceProduct) => invoiceProduct.ProductId === product._id))}
                            value={products.find((product) => product._id === invoiceProduct?.ProductId)}
                            onChange={(product) => {
                              handleProductChange(index, {
                                ProductId: product._id,
                                InvoiceId: invoice._id,
                                quantity: product.quantity,
                                unit: product.unit,
                                price: product.price,
                                currency: product.currency,
                                total: product.total,
                                name: product.name,
                                description: product.description,
                                category: product.category,
                                discount: product.discount,
                                taxRate: product.taxRate,
                                TaxId: product.TaxId,
                                taxValue: 0,
                              });
                            }}
                            listHeader={
                              <button
                                onClick={() => {
                                  setCreatedProductIndex(index);
                                  setShowCreateProduct(true);
                                }}
                                className=" w-full bg-black-100 text-white px-3 py-2 flex items-center justify-between font-semibold text-sm">
                                {t("products.create_a_product")} <span>+</span>
                              </button>
                            }
                            placeholder={t("product")}
                            getLabel={(product) => product.name}
                          />
                        )}
                      </span>
                      {isPrinting ? (
                        <div className="whitespace-normal break-words " dangerouslySetInnerHTML={{ __html: invoiceProduct.description?.replaceAll("\n", "<br>") }} />
                      ) : (
                        <textarea
                          type="text"
                          className="w-full border px-2 h-14 mt-1"
                          placeholder="Description"
                          value={invoiceProduct.description ?? ""}
                          onChange={(e) => {
                            handleProductChange(index, {
                              ...invoiceProduct,
                              description: e.target.value,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td className="text-right pl-3">
                    {isPrinting ? (
                      invoiceProduct.quantity
                    ) : (
                      <input
                        type="number"
                        className="w-24 border p-2 text-right"
                        value={invoiceProduct.quantity ?? 0}
                        onChange={(e) => {
                          handleProductChange(index, {
                            ...invoiceProduct,
                            quantity: e.target.value,
                          });
                        }}
                      />
                    )}
                  </td>
                  <td className="text-right whitespace-nowrap pl-3">
                    {isPrinting ? (
                      invoiceProduct.price
                    ) : (
                      <input
                        type="number"
                        className="w-24 border text-right p-2"
                        value={invoiceProduct.price ?? 0}
                        onChange={(e) => {
                          handleProductChange(index, {
                            ...invoiceProduct,
                            price: e.target.value,
                          });
                        }}
                      />
                    )}
                  </td>
                  <td className="text-right whitespace-nowrap pl-3">
                    {isPrinting ? (
                      taxOptions.find((value) => value._id === invoiceProduct.TaxId)?.name
                    ) : (
                      <Combobox
                        options={taxOptions}
                        value={taxOptions.find((value) => value._id === invoiceProduct.TaxId)}
                        onChange={(value) =>
                          handleProductChange(index, {
                            ...invoiceProduct,
                            TaxId: value._id,
                          })
                        }
                        inputClassName="py-2 text-md"
                        width="w-44"
                        listHeader={
                          <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                            {t("add_vat")} <span>+</span>
                          </button>
                        }
                        placeholder="Type"
                        getLabel={(tax) => tax.name}
                      />
                    )}
                  </td>
                  <td className={`text-right whitespace-nowrap flex justify-end ${isPrinting ? "" : "!pt-7"}`}>{invoiceProduct.taxValue ?? 0}</td>
                  <td className="text-right whitespace-nowrap pl-4">
                    {invoiceProduct.subTotal ?? 0}
                    <button
                      className={
                        isPrinting
                          ? "hidden"
                          : "bg-transparent ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white p-2 text-sm border border-blue-500 hover:border-transparent rounded"
                      }
                      onClick={() => removeProduct(index)}>
                      X
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          <tr className={isPrinting ? "hidden" : ""}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                className="mb-5 mt-5 w-full bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 text-sm border border-blue-500 hover:border-transparent rounded"
                onClick={() => addProduct()}>
                {t("invoices.editor.add_product")}
              </button>
            </td>
            <td className="pt-2" />
          </tr>
        </tbody>
      </table>
      <CreateProduct
        open={showCreateProduct}
        onClose={() => setShowCreateProduct(false)}
        onCreated={(data) => {
          getProducts();
          setCreatedProduct({ ...data });
        }}
      />
    </div>
  );
};

const SummaryTable = ({ invoice, setInvoice, isPrinting, taxOptions }) => {
  const { t } = useTranslation();
  const currency = currencyToStr(invoice.currency);
  const navigate = useNavigate();

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  return (
    <div className="flex justify-end mt-4">
      <div className="w-96">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-[#F5F5F5] border-y border-y-[#C9C8C9] text-sm text-black font-semibold py-2.5">
              <th className="py-2.5 text-center font-bold text-lg" colSpan={2}>
                {getTranslation("summary", invoice.language)}
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-black">
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3"> {getTranslation("sub_total", invoice.language)} </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.subTotal}
                {currency}
              </td>
            </tr>
            {(!isPrinting || invoice.discount > 0) && (
              <tr className="border-b border-b-[#C9C8C9]">
                <td className="py-2.5 pl-3">
                  <div className="flex justify-between align-middle items-center">
                    <span>{getTranslation("discount", invoice.language)}</span>
                    <div>
                      {isPrinting ? (
                        invoice.discount
                      ) : (
                        <input
                          name="discount"
                          type="number"
                          value={invoice.discount ?? ""}
                          onChange={(e) => setInvoice({ ...invoice, discount: e.target.value })}
                          className="border-[1px] border-gray-200 placeholder:text-gray-300 rounded-lg w-16 h-7 px-2"
                          placeholder="10"
                        />
                      )}
                      <span className="ml-1">%</span>
                    </div>
                  </div>
                </td>
                <td className="py-2.5 text-right pr-3">{invoice.discount ? parseFloat(-(invoice.subTotal ?? 0) * (parseFloat(invoice.discount ?? 0) / 100)).toFixed(2) : 0}</td>
              </tr>
            )}
            {invoice.discount > 0 && (
              <tr className="border-b border-b-[#C9C8C9]">
                <td className="py-2.5 pl-3">Total excl. tax</td>
                <td className="py-2.5 text-right pr-3">
                  {parseFloat(invoice.totalTaxExcluded).toFixed(2)}
                  {currency}
                </td>
              </tr>
            )}
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3 flex gap-2 items-center">
                <div>{getTranslation("tax", invoice.language)}</div>
                <div>
                  {isPrinting ? (
                    taxOptions.find((value) => value._id === invoice.TaxId)?.name
                  ) : (
                    <Combobox
                      options={taxOptions}
                      value={taxOptions.find((value) => value._id === invoice.TaxId)}
                      onChange={(value) => setInvoice({ ...invoice, TaxId: value._id || null })}
                      width="w-44"
                      listHeader={
                        <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                          {t("add_vat")} <span>+</span>
                        </button>
                      }
                      placeholder="Type"
                      getLabel={(tax) => tax.name}
                    />
                  )}
                </div>
              </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.taxValue}
                {currency}
              </td>
            </tr>
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3"> {getTranslation("invoices_editor_total_incl_tax", invoice.language)} </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.totalTaxIncluded}
                {currency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const clientorSupplierOption = (item) => {
  if (!item) return null;
  return item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName;
};

const PreviewModal = ({ isOpen, invoice, onClose }) => {
  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]" onClose={onClose}>
      <iframe src={invoice.file} width="100%" height="100%" />
    </Modal>
  );
};

export default Editor;
