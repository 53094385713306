import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/utils/Select";

const Table = ({ organizations }) => {
  const { t } = useTranslation();
  const [selectedLevel, setSelectedLevel] = useState("All");

  const levelOptions = ["All", "1", "2", "3"];

  const getFilteredOrganizations = () => {
    if (selectedLevel === "All") {
      return organizations;
    }
    return organizations.filter((org) => org.level.toString() === selectedLevel);
  };

  const filteredOrganizations = getFilteredOrganizations();

  return (
    <div>
      <div className="mb-6 flex items-center gap-2 w-28">
        <label className=" text-gray-500">Level:</label>
        <Select options={levelOptions} value={selectedLevel} onChange={(e) => setSelectedLevel(e)} width="w-full md:w-80" nullable={false} />
      </div>

      <div className="overflow-x-auto h-fit flow-root min-w-full align-middle overflow-hidden rounded-lg border">
        <table className="min-w-full">
          <thead className="border-b">
            <tr>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
                {"Level"}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
                {t("promo_code.organization")}
              </th>
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
                {t("promo_code.plan")}
              </th>
              {selectedLevel !== "1" && (
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
                  {t("promo_code.referrer")}
                </th>
              )}
              <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
                {t("promo_code.referral_start_at")}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredOrganizations.map((organization) => (
              <tr key={organization._id}>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{getLevel(organization.level)}</td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <div className="flex items-center">
                    {organization.logo ? (
                      <img src={organization.logo} alt="Avatar" className="h-8 w-8 rounded-full" />
                    ) : (
                      <span className="h-6 w-6 flex items-center justify-center rounded bg-blue-500 text-white font-bold">{organization.name.charAt(0).toUpperCase()}</span>
                    )}
                    <span className="ml-3">{organization.name}</span>
                  </div>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{organization.plan}</td>
                {selectedLevel !== "1" && (
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {organization.referrerOrganization && (
                      <div className="flex items-center">
                        {organization.referrerOrganization.logo ? (
                          <img src={organization.referrerOrganization.logo} alt="Avatar" className="h-8 w-8 rounded-full" />
                        ) : (
                          <span className="h-6 w-6 flex items-center justify-center rounded bg-blue-500 text-white font-bold">
                            {organization.referrerOrganization.name.charAt(0).toUpperCase()}
                          </span>
                        )}
                        <span className="ml-3">{organization.referrerOrganization.name}</span>
                      </div>
                    )}
                  </td>
                )}
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{organization.referralStartAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const getLevel = (level) => {
  if (level === "1") {
    return <span className="inline-flex items-center gap-x-1.5 rounded-md bg-[#BBF7D0] px-2 py-1 text-xs font-medium text-green-800">Level 1</span>;
  }
  if (level === "2") {
    return <span className="inline-flex items-center gap-x-1.5 rounded-md bg-[#FED7AA] px-2 py-1 text-xs font-medium text-orange-700">Level 2</span>;
  }
  return <span className="inline-flex items-center gap-x-1.5 rounded-md bg-[#E9D5FF] px-2 py-1 text-xs font-medium text-purple-700">Level 3</span>;
};

export default Table;
