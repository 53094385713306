import { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import api from "@services/api";
import Modal from "@components/utils/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/icon.png";
import { setUser } from "../../redux/auth/actions";
import { ENVIRONMENT } from "@config";
import { SiTrustpilot } from "react-icons/si";

const NPSModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [nps, setNps] = useState(null);
  const [comment, setComment] = useState("");
  const [showTrustpilot, setShowTrustpilot] = useState(false);

  const { user, organization } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const submit = async (values) => {
    try {
      const res = await api.put(`/user/${user._id}`, values);
      dispatch(setUser(res.data));
    } catch (e) {}
  };

  const handleDisplayNPS = async () => {
    try {
      if (ENVIRONMENT !== "production") return;
      if (user.NpsDoneAt) return;
      // if the nps was displayed less than 2 days ago, do not display it again
      if (user.NpsDisplayedAt && new Date() - new Date(user.NpsDisplayedAt) < 2 * 24 * 60 * 60 * 1000) return;

      const responseTransactions = await api.post(`/transaction/search`, { OrganizationId: organization._id, status: "RECONCILED", per_page: 20 });
      if (responseTransactions.data?.transactions?.length < 10 && user.totalInvoicesCreated < 10) return;

      setOpen(true);
      const { data } = await api.put(`/user/${user._id}`, { NpsDisplayedAt: new Date() });
      dispatch(setUser(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleDisplayNPS();
  }, []);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} innerClassName="flex max-w-[40rem] w-[90vw] max-h-[calc(100vh-5rem)] ">
      <div className="w-full max-h-full flex flex-col overflow-y-auto transform rounded-2xl py-4 px-8 bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-center mb-4">
          <img src={Logo} alt="Logo" className="h-12" />
        </div>
        {!showTrustpilot ? (
          <>
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">{t("nps.title")}</h2>
              <p className="text-gray-600 mb-6">{t("nps.description")}</p>
              <label className="block text-gray-800 my-2">{t("nps.rating")}</label>
              <div className="flex justify-between px-2">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                  <div key={value} className="group flex flex-col items-center cursor-pointer mt-2" onClick={() => setNps(value)}>
                    <div className={`text-sm ${nps === value ? "text-blue-600 font-bold" : "text-gray-600"}`}>{value}</div>
                    <div className={`w-5 h-5 border rounded-full ${nps === value ? "bg-blue-600" : "bg-gray-200 group-hover:bg-blue-200"}`} />
                  </div>
                ))}
              </div>
            </div>
            <div className="my-4">
              <label className="block text-gray-800 mb-2">{t("nps.comment")}</label>
              <textarea
                rows={5}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder={t("nps.comment")}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center gap-2 hover:bg-blue-700 disabled:opacity-50"
                onClick={() => {
                  submit({ NpsReferralGrade: nps, NpsDoneAt: new Date(), NpsFeedback: comment });
                  if (nps < 8) setOpen(false);
                  else setShowTrustpilot(true);
                }}
                disabled={nps === null}>
                <BsCheckCircle />
                {t("nps.submit")}
              </button>
            </div>
          </>
        ) : (
          <TrustBox />
        )}
      </div>
    </Modal>
  );
};

export default NPSModal;

const TrustBox = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.Auth);
  const handleClick = () => {
    try {
      api.put(`/user/${user._id}`, { NpsTrustpilotClickAt: new Date() });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <h2 className="text-base font-base text-gray-800 mb-4 text-center">{t("nps.trustpilot.title")}</h2>
      <p className="text-black text-2xl mb-6 text-center font-bold">{t("nps.trustpilot.text1")}</p>
      <p className="text-gray-600 mb-2 text-center">{t("nps.trustpilot.text2")}</p>
      <p className="text-gray-600 mb-6 text-center">{t("nps.trustpilot.text3")}</p>
      <a
        onClick={handleClick}
        className="flex gap-1 items-center transparent-btn border border-[#00B67A] "
        href={`https://${user.language === "FR" ? "fr" : "www"}.trustpilot.com/review/finotor.com`}
        target="_blank"
        rel="noopener noreferrer">
        {t("nps.trustpilot.button")}
        <SiTrustpilot className="text-[#00B67A]" />
        <span className="text-xs font-semibold">Trustpilot</span>
      </a>
    </div>
  );
};
