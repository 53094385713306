import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import api from "@services/api";
import Loader from "@components/utils/Loader";

import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import { Combobox } from "@components/utils/Combobox";
import { Select } from "@components/utils/Select";
import ActionsMenu from "@components/utils/ActionsMenu";
import CreatePurchaseInvoice from "./createPurchaseInvoices";
import Modal from "@components/utils/Modal";
import { currencies } from "@constants";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import FileInput from "../../components/utils/FileInput";
import FilesInput from "../../components/utils/FilesInput";
import { Mixpanel } from "../../services/mixpanel";
import CreateSupplier from "@components/modals/createSupplier.jsx";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const query = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const [open, setOpen] = useState(query.get("id"));
  const [filter, setFilter] = useState({ per_page: 8, page: 1, search: "" });
  const [purchaseInvoice, setPurchaseInvoice] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const { organization } = useSelector((state) => state.Auth);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });

    setPurchaseInvoice(data.purchaseInvoices);
    setTotal(data.total);
  };

  const deletePurchaseInvoices = async (invoiceID) => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/purchase-invoice/${invoiceID}`);
    toast.success(t("toast.sucess.removed"));
    getPurchaseInvoices();
  };

  useEffect(() => {
    if (!organization) return;
    getPurchaseInvoices();
  }, [filter, organization]);

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS["page_view_purchase-invoices"]);
  }, []);

  if (!purchaseInvoice) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <div className="text-2xl font-bold "> {t("purchase_invoices")} </div>
              <p className="mb-5 mt-2 text-base text-gray-600">{t("purchase_invoices.dsc")}</p>
            </div>
            <CreatePurchaseInvoice
              onCreated={(el) => {
                getPurchaseInvoices();
                navigate(`/purchase-invoice?id=${el._id}`);
              }}
            />
          </div>

          <SearchBar search={filter.search} setFilter={setFilter} />
          <EditInvoiceModal
            isOpen={open}
            onClose={() => {
              navigate("/purchase-invoice");
              setOpen(false);
            }}
            onSave={getPurchaseInvoices}
            showCreateSupplierModal={() => {
              setOpen(false);
              setShowCreateSupplier(true);
              setCreatedSupplier(null);
            }}
            createdSupplier={createdSupplier}
            taxOptions={organization.Taxes}
          />
          <CreateSupplier
            open={showCreateSupplier}
            onClose={() => {
              setShowCreateSupplier(false);
            }}
            onCreated={(data) => {
              setCreatedSupplier({ ...data });
            }}
          />
          <TablePurchaseInvoice purchaseInvoice={purchaseInvoice} onRemove={deletePurchaseInvoices} />
          <div className="flex flex-row items-center justify-center mt-4">
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditInvoiceModal = ({ isOpen, onClose, onSave, showCreateSupplierModal, createdSupplier, taxOptions }) => {
  const [purchaseInvoice, setPurchaseInvoice] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchPurchaseInvoice = async (purchaseInvoiceId) => {
    const res = await api.get(`/purchase-invoice/${purchaseInvoiceId}`);
    if (!res.ok) toast.error(t("toast.error.fetching_trans"));
    setPurchaseInvoice(res.data);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const purchaseInvoiceId = query.get("id");
    if (!purchaseInvoiceId) return onClose();
    fetchPurchaseInvoice(purchaseInvoiceId);
  }, [isOpen]);

  const getSuppliers = async () => {
    const { data } = await api.post("/supplier/search", {
      per_page: 1000,
    });

    setSuppliers(data.suppliers);
  };
  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    if (createdSupplier) {
      getSuppliers();
      setPurchaseInvoice({ ...purchaseInvoice, SupplierId: createdSupplier._id || null });
    }
  }, [createdSupplier]);

  const handleClose = () => {
    setPurchaseInvoice(null);
    onClose();
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/purchase-invoice/${purchaseInvoice._id}`, { ...purchaseInvoice, amount: purchaseInvoice.amount.replace(",", ".") });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.updated_sucessfully"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  if (loading) return <Loader />;
  if (!purchaseInvoice) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex max-w-[60rem] w-[60vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-4 text-left text-[26px] font-semibold"> {t("purchase_invoices.edit")} </div>
        <div className="py-4 px-4 overflow-auto">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-4 grid-rows-3 gap-4">
                <div className="flex flex-col row-start-1 col-span-2">
                  <div className="text-base mb-1 font-semibold capitalize"> {t("reference")} </div>
                  <input
                    type="text"
                    value={purchaseInvoice.reference}
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, reference: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                    placeholder={t("reference")}
                  />
                </div>
                <div className="flex flex-col col-span-2">
                  <div className="text-base mb-1 font-semibold capitalize"> {t("supplier")} </div>
                  <Combobox
                    options={suppliers}
                    value={suppliers.find((supplier) => supplier._id === purchaseInvoice?.SupplierId)}
                    placeholder={t("choose_supplier")}
                    onChange={(selectedClient) => {
                      setPurchaseInvoice({ ...purchaseInvoice, SupplierId: selectedClient?._id || null });
                    }}
                    getLabel={(supplier) => {
                      if (supplier.entityType === "COMPANY") return supplier.companyName || "";
                      else if (supplier.entityType === "INDIVIDUAL") return supplier.firstname + " " + supplier.lastname || "";
                      return "";
                    }}
                    listHeader={
                      <button
                        className="px-3 py-2 bg-black-100 w-full text-white flex items-center justify-between font-semibold text-sm"
                        onClick={() => showCreateSupplierModal()}>
                        {t("supplier.create_supplier")} <span>+</span>
                      </button>
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold capitalize"> {t("currency")} </div>
                  <Select
                    options={currencies}
                    value={currencies.find((currency) => currency.id == purchaseInvoice.currency)}
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, currency: e.id })}
                    getLabel={(e) => (
                      <span>
                        <span className="bg-neutral-100 px-2 mr-2">{e.symbol}</span>
                        {e.name}
                      </span>
                    )}
                    placeholder={t("select_currency")}
                    width="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                    nullable={false}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold capitalize">
                    {t("amount")} ({purchaseInvoice.currency})
                  </div>
                  <input
                    type="text"
                    value={purchaseInvoice.amount}
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, amount: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                    placeholder={t("amount")}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base mb-1 font-semibold capitalize">
                    {t("vat")} ({purchaseInvoice.currency})
                  </div>
                  <Combobox
                    options={taxOptions}
                    value={taxOptions.find((value) => value._id === purchaseInvoice.TaxId)}
                    onChange={(value) => setPurchaseInvoice({ ...purchaseInvoice, TaxId: value._id || null })}
                    width="w-44"
                    listHeader={
                      <button
                        type="button"
                        className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs"
                        onClick={navigateToOrgSettings}>
                        {t("add_vat")} <span>+</span>
                      </button>
                    }
                    placeholder="Type"
                    getLabel={(tax) => tax.name}
                  />
                </div>

                <div className="flex flex-col row-start-3">
                  <div className="text-base mb-1 font-semibold capitalize"> {t("purchase_invoices.issuedate")} </div>
                  <input
                    type="date"
                    value={purchaseInvoice.issueDate ? purchaseInvoice.issueDate.slice(0, 10) : ""}
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, issueDate: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                  />
                </div>

                <div className="flex flex-col row-start-3">
                  <div className="text-base mb-1 font-semibold capitalize"> {t("purchase_invoices.duedate")} </div>
                  <input
                    type="date"
                    value={purchaseInvoice.dueDate ? purchaseInvoice.dueDate.slice(0, 10) : ""}
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, dueDate: e.target.value })}
                    className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                  />
                </div>
              </div>

              <FileInput
                value={purchaseInvoice?.file}
                onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, file: e.target.value })}
                name="{purchaseInvoice._id}"
                folder="purchaseInvoiceTransaction"
              />

              <hr className="mb-2" />
              <h2 className="text-primary text-base mb-1 font-semibold underline"> {t("purchase_invoices.additional_files")} </h2>

              <FilesInput
                values={purchaseInvoice.files}
                onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, files: e.target.values })}
                name={purchaseInvoice._id}
                folder="purchaseInvoiceTransaction"
              />
            </div>
          </form>
        </div>
        <div className="flex justify-between w-full mt-3">
          <button type="submit" className="btn-secondary" onClick={handleClose}>
            {t("cancel")}
          </button>
          <div>
            <button
              type="submit"
              className="btn-primary"
              onClick={(e) => {
                handleSubmit(e);
                Mixpanel.track(MIXPANEL_EVENTS.btn_save_purchase_invoice);
              }}>
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
const TablePurchaseInvoice = ({ purchaseInvoice, onRemove }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSupplierName = (supplier) => {
    if (!supplier) return "";
    if (supplier.entityType === "COMPANY") return supplier.companyName || "";
    else if (supplier.entityType === "INDIVIDUAL") return supplier.firstname + " " + supplier.lastname || "";
    return "";
  };

  return (
    <div className="overflow-x-auto mt-4 flow-root min-w-full align-middle rounded-lg border">
      <table className="min-w-full ">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("reference")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("vat")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("currency")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("purchase_invoices.issuedate")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("purchase_invoices.duedate")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("supplier")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {purchaseInvoice?.map((invoice) => (
            <tr key={invoice._id} className="hover:bg-[#dee2e6]">
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div
                  className="cursor-pointer underline font-bold"
                  onClick={() => {
                    navigate(`/purchase-invoice?id=${invoice._id}`);
                    Mixpanel.track(MIXPANEL_EVENTS.page_view_purchase_invoice_edit);
                  }}>
                  {invoice.reference}
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.Tax?.name}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.amount}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.currency}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice?.issueDate ? new Date(invoice.issueDate).toLocaleDateString().split("T")[0] : "-"}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice?.dueDate ? new Date(invoice.dueDate).toLocaleDateString().split("T")[0] : "-"}</div>
              </td>

              <td className="whitespace-nowrap px-2 py-2 text-sm">{getSupplierName(invoice.Supplier)}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm z-20 w-4">
                <ActionsMenu actions={[{ label: t("delete"), callback: () => onRemove(invoice._id) }]} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
