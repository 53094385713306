import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import { Select } from "@components/utils/Select";
import MultipleSelectInput from "@components/utils/MultipleSelectInput";
import Modal from "@components/utils/Modal";
import { statuses } from "@constants";
import { IoCloseCircleSharp, IoSaveOutline } from "react-icons/io5";
import { HiOutlinePaperAirplane } from "react-icons/hi2";
import FileInput from "../../components/utils/FileInput";
import FilesInput from "../../components/utils/FilesInput";

import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { copyToClipboard } from "../../utils/index";

import { ENVIRONMENT } from "../../config";

const Viewer = ({ invoice }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(invoice);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const updateInvoice = async () => {
    try {
      const req = await api.put(`/invoice/${values._id}`, { ...values });
      if (!req.ok) throw req.code;
      toast.success(t("toast.sucess.invoice_updated"));
    } catch (error) {
      console.error("Error updating invoice", error);
      toast.error(t("toast.error.invoice_updating"));
    }
  };

  async function get() {
    try {
      const { data: invoiceData } = await api.get(`/invoice/${values?._id}`);
      setValues(invoiceData);
    } catch (error) {
      console.error(t("toast.error"), error);
      toast.error(error.code);
    }
  }

  if (!values) return <Loader />;

  return (
    <div className="mx-auto bg-white">
      <div className="flex flex-col py-10 px-8">
        <SendModal isOpen={openSendModal} invoice={values} setInvoice={setValues} onClose={() => setOpenSendModal(false)} onDone={get} />
        <PaymentModal isOpen={openPaymentModal} onClose={() => setOpenPaymentModal(false)} invoice={values} setInvoice={setValues} onUpdate={updateInvoice} />
        <div className="grid justify-center grid-cols-12">
          <div className="col-start-1 col-end-9 shadow-xl">
            <File invoice={values} />
          </div>
          <div className="col-start-9 col-end-13">
            <div className="flex flex-col gap-4 pl-8">
              <div className="flex flex-col relative">
                <div className="text-sm font-semibold mb-1"> {t("status")} </div>
                <Select
                  options={statuses.filter((status) => status != "DRAFT")}
                  value={values.status}
                  getLabel={(status) => (status ? t(`invoices.status.${status}`) : null)}
                  onChange={(status) => {
                    setValues({ ...values, status });
                  }}
                  placeholder={t("status")}
                />
              </div>
              <div className="flex flex-col relative">
                {values.sent && values.sentAt && (
                  <div className="flex gap-2 text-sm mb-1">
                    <span className="font-semibold">{t("invoices.sent_at")}:</span>
                    {new Date(values.sentAt).toLocaleDateString()}{" "}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <div className=" text-sm font-semibold mb-1"> {t("memo_note")} </div>
                <textarea
                  type="text"
                  value={values.memo ?? ""}
                  onChange={(e) => setValues({ ...values, memo: e.target.value })}
                  className="border-[1px] border-gray-200 placeholder:text-gray-300 rounded-lg  px-3 py-2"
                  placeholder={t("memo_note.to_enter.placeholder")}
                />
                <FilesInput
                  values={values.memoFiles}
                  onChange={(e) => setValues({ ...values, memoFiles: e.target.values ? e.target.values.flat() : [] })}
                  name={values._id}
                  folder={`/invoice/${values._id}/memoFiles`}
                />
              </div>
              <div>
                <button
                  className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black"
                  onClick={() => {
                    Mixpanel.track(MIXPANEL_EVENTS.btn_add_payment_link_invoice);
                    setOpenPaymentModal(true);
                  }}>
                  {t("invoices.editor.add_payment_link")}
                </button>
              </div>

              <div className="flex justify-between gap-4 mt-5">
                <button
                  className="flex items-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black"
                  onClick={async () => {
                    await updateInvoice();
                    get();
                  }}>
                  <IoSaveOutline className="text-base text-gray-500" />
                  <span className="">{t("save")}</span>
                </button>
                <button
                  onClick={() => setOpenSendModal(true)}
                  className="flex items-center gap-2 py-2 px-4 bg-blue-500 hover:bg-blue-400 text-white tracking-wide text-sm border border-blue-500 hover:border-transparent rounded">
                  <HiOutlinePaperAirplane className="text-base text-white" />
                  <span className="">{t("invoices.editor.send_invoice")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentModal = ({ isOpen, onClose, invoice, setInvoice, onUpdate }) => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [gocardlessStatus, setGocardlessStatus] = useState("not_connected");
  const [paymentDetails, setPaymentDetails] = useState({
    amount: invoice.totalTaxIncluded || "0",
    description: `[${organization?.name}] - ${invoice?.reference}`,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (organization?.gocardlessAccessToken) getGocardlessAccountStatus();
    setLoading(false);
  }, []);

  const getGocardlessAccountStatus = async () => {
    try {
      const { data } = await api.get(`/gocardless/status/${organization._id}`);
      setGocardlessStatus(data.creditor.verification_status);
    } catch (error) {
      console.error("Error fetching Gocardless account status:", error);
    }
  };

  const redirectGocardless = async () => {
    try {
      Mixpanel.track(MIXPANEL_EVENTS.btn_connect_gocardless_invoice);
      setLoading(true);
      const { data } = await api.post("/gocardless/connect", {
        invoiceId: invoice._id,
      });
      if (!data.link) return toast.error(t("invoices.gocardless.error_no_link"));
      window.location.href = data.link;
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  const generatePaymentLink = async () => {
    try {
      Mixpanel.track(MIXPANEL_EVENTS.btn_generate_payment_link_gocardless_invoice);

      setLoading(true);

      const { ok, data } = await api.post("/gocardless/generate-payment-link", {
        organizationId: organization._id,
        paymentDetails: {
          amount: Math.round(parseFloat(paymentDetails.amount) * 100),
          description: paymentDetails.description,
        },
      });
      if (!ok) return toast.error(t("invoices.gocardless.error_generating_link"));
      setInvoice({ ...invoice, paymentLink: data.paymentLink });
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  const savePaymentLink = async () => {
    try {
      setLoading(true);
      onUpdate();
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };
  if (loading) {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <Loader />
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "not_connected") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <button onClick={onClose} className="absolute p-2 top-0 right-0 overflow-hidden">
            <IoCloseCircleSharp className="w-10 h-10" />
          </button>
          <h2 className="text-lg font-semibold">{t("invoices.gocardless.no_account")}</h2>
          <div className="flex justify-around gap-4 mt-5">
            <button className="btn-primary" onClick={redirectGocardless}>
              {t("invoices.gocardless.btn_goto")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "action_required") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <button onClick={onClose} className="absolute p-2 top-0 right-0 overflow-hidden">
            <IoCloseCircleSharp className="w-10 h-10" />
          </button>
          <h2 className="text-lg font-semibold">{t("invoices.gocardless.action_required")}</h2>
          <div className="flex justify-around gap-4 mt-5">
            <button
              className="btn-primary"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_action_required_gocardless_invoice);
                const url = ENVIRONMENT === "development" ? "https://verify-sandbox.gocardless.com" : "https://verify.gocardless.com";
                window.location.href = url;
              }}>
              {t("invoices.gocardless.btn_goto")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "in_review") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <button onClick={onClose} className="absolute p-2 top-0 right-0 overflow-hidden">
            <IoCloseCircleSharp className="w-10 h-10" />
          </button>
          <h2 className="text-lg font-semibold">{t("invoices.gocardless.in_review")}</h2>
          <div class="flex space-x-2 justify-center items-center bg-white mt-8">
            <div class="h-6 w-6 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div class="h-6 w-6 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div class="h-6 w-6 bg-black rounded-full animate-bounce"></div>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "successful" && invoice.paymentLink) {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative p-12">
          <button onClick={onClose} className="absolute p-2 top-0 right-0 overflow-hidden">
            <IoCloseCircleSharp className="w-10 h-10" />
          </button>
          <div className="w-full flex justify-between gap-2 text-blue-600 text-sm items-center shadow-inner space-x-3 p-2 bg-gray-100 rounded-lg">
            <a href={invoice.paymentLink} target="_blank" rel="noopener noreferrer" className=" hover:underline">
              {invoice.paymentLink}
            </a>
            <button
              type="button"
              onClick={() => {
                copyToClipboard(invoice.paymentLink);
                toast.success(t("invoices.gocardless.link_copied"));
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
              {t("invoices.gocardless.copy_link")}
            </button>
          </div>
          <div className="flex gap-4 my-4">
            <button
              className="flex items-center justify-center gap-2 py-2 px-4 bg-white tracking-wide  text-sm border border-gray-300 rounded hover:border-gray-600 text-black"
              onClick={() => setInvoice({ ...invoice, paymentLink: null })}>
              {t("invoices.editor.regenerate_link")}
            </button>
            <button className="btn-primary" onClick={savePaymentLink}>
              {t("invoices.gocardless.save_and_quit")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
      <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative p-4">
        <button onClick={onClose} className="absolute p-2 top-0 right-0 overflow-hidden">
          <IoCloseCircleSharp className="w-10 h-10" />
        </button>
        <h2 className="text-lg font-semibold">{t("invoices.gocardless.generate_link")}</h2>
        <div className="w-1/3 flex flex-col items-center gap-4 mt-5">
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="price-input" className="text-sm font-semibold">
              {t("invoices.gocardless.amount")}
            </label>
            <input
              id="price-input"
              type="text"
              className="text-right rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              value={paymentDetails.amount}
              onChange={(e) => {
                const newValue = e.target.value;

                if (/^\d*\.?\d{0,2}$/.test(newValue)) {
                  setPaymentDetails({ ...paymentDetails, amount: newValue });
                }
              }}
              placeholder="0.00"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="price-input" className="text-sm font-semibold">
              {t("invoices.gocardless.description")}
            </label>
            <textarea
              type="text"
              className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              value={paymentDetails.description}
              onChange={(e) => {
                setPaymentDetails({ ...paymentDetails, description: e.target.value });
              }}
            />
          </div>
        </div>
        <button className="btn-primary mt-5" onClick={generatePaymentLink}>
          {t("invoices.gocardless.btn_generate_link")}
        </button>
      </div>
    </Modal>
  );
};

const SendModal = ({ isOpen, invoice, setInvoice, onClose, onDone }) => {
  const { t } = useTranslation();
  const [showCcRecipients, setShowCcRecipients] = useState(true);
  const [showBccRecipients, setShowBccRecipients] = useState(true);
  const user = useSelector((state) => state.Auth.user);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    if (!invoice.ccRecipients || invoice.ccRecipients?.length === 0) setShowCcRecipients(false);
    if (!invoice.bccRecipients || invoice.bccRecipients?.length === 0) setShowBccRecipients(false);
    if (invoice.memoFiles) setFiles(invoice.memoFiles);
  }, [isOpen]);

  async function sendInvoice(e) {
    try {
      e.preventDefault();
      if (!window.confirm(t("confirm_message.mail"))) {
        return;
      }
      if (!invoice.recipients.length) return toast.error(t("invoices.editor.error_no_recipients"));
      if (!invoice.name) return toast.error(t("invoices.editor.error_no_subject"));
      if (!invoice.messageBody) return toast.error(t("invoices.editor.error_no_message_body"));

      const submittedValues = {
        name: invoice.name,
        messageBody: invoice.messageBody,
        recipients: invoice.recipients,
        ccRecipients: [...(invoice?.ccRecipients || []), user.email],
        bccRecipients: invoice?.bccRecipients,
        includeInvoiceFile: invoice.includeInvoiceFile,
        attachment: [],
      };
      if (invoice.includeInvoiceFile && invoice.file) {
        submittedValues.attachment = [...submittedValues.attachment, { url: invoice.file, name: `invoice_${invoice.reference}.pdf` }];
      }
      if (files.length > 0) {
        submittedValues.attachment = [...submittedValues.attachment, ...files.map((file) => ({ url: file, name: file.split("/").pop() }))];
      }

      await api.put(`/invoice/send/${invoice._id}`, submittedValues);

      toast.success(t("invoices.sent"));
      onDone();
      onClose();
    } catch (error) {
      console.log(error);
      toast.error(t("toast.error.invoice.sending"));
    }
  }

  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]" onClose={onClose}>
      <form className="flex flex-col gap-2 p-3 pt-5 w-full" onSubmit={sendInvoice}>
        <h2 className="text-lg font-semibold mb-2">{t("invoices.editor.send_invoice")}</h2>
        <div className="flex flex-col">
          <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.recipients")}</div>
          <div className="flex items-start gap-1">
            <div className="flex-1">
              <MultipleSelectInput
                value={invoice.recipients ?? []}
                placeholder={t("invoices.editor.recipients_placeholder")}
                onChange={(e) => setInvoice({ ...invoice, recipients: e.map((e) => e.trim()) })}
              />
            </div>
            <div className="flex space-x-2">
              <button
                type="button"
                className={`px-3 py-1 rounded-md ${showCcRecipients ? "bg-gray-200 text-gray-700" : "text-gray-500 hover:bg-gray-100"}`}
                onClick={() => setShowCcRecipients(!showCcRecipients)}>
                {t("invoices.editor.add_copy_recipients")}
              </button>
              <button
                type="button"
                className={`px-3 py-1 rounded-md ${showBccRecipients ? "bg-gray-200 text-gray-700" : "text-gray-500 hover:bg-gray-100"}`}
                onClick={() => setShowBccRecipients(!showBccRecipients)}>
                {t("invoices.editor.add_bcc_recipients")}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start">
          {Boolean(showCcRecipients) && (
            <>
              <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.copy_recipients")}</div>
              <MultipleSelectInput
                className="w-full"
                value={invoice.ccRecipients ?? []}
                placeholder={t("invoices.editor.copy_recipients_placeholder")}
                onChange={(e) => setInvoice({ ...invoice, ccRecipients: e.map((e) => e.trim()) })}
              />
            </>
          )}
        </div>
        <div className="flex flex-col items-start">
          {Boolean(showBccRecipients) && (
            <>
              <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.bcc_recipients")}</div>
              <MultipleSelectInput
                className="w-full"
                value={invoice.bccRecipients ?? []}
                placeholder={t("invoices.editor.bcc_recipients_placeholder")}
                onChange={(e) => setInvoice({ ...invoice, bccRecipients: e.map((e) => e.trim()) })}
              />
            </>
          )}
        </div>
        <div className="mt-3 flex flex-col">
          <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.subject")}</div>
          <input
            className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
            name="subject"
            value={invoice.name ?? ""}
            onChange={(e) => setInvoice({ ...invoice, name: e.target.value })}
          />
        </div>
        {invoice.paymentLink && (
          <>
            <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.paymentLink")}</div>
            <div className="w-full flex justify-between gap-2 text-blue-600 text-sm items-center shadow-inner space-x-3 p-2 bg-gray-100 rounded-lg">
              <a href={invoice.paymentLink} target="_blank" rel="noopener noreferrer" className=" hover:underline">
                {invoice.paymentLink}
              </a>
              <button
                type="button"
                onClick={() => {
                  copyToClipboard(invoice.paymentLink);
                  toast.success(t("invoices.gocardless.link_copied"));
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                {t("invoices.gocardless.copy_link")}
              </button>
            </div>
          </>
        )}
        <div className="mt-3 px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.message_body")}</div>
        <textarea
          className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
          rows="12"
          name="description"
          value={invoice.messageBody ?? ""}
          onChange={(e) => setInvoice({ ...invoice, messageBody: e.target.value })}
        />
        <div className="flex flex-col items-start">
          <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.attachments")} :</div>

          {invoice.file ? (
            <div className="flex gap-8">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="focus:ring-0 my-auto inline-flex items-center cursor-pointer text-primary bg-[#F3F4ED] rounded-full"
                  checked={invoice.includeInvoiceFile ?? true}
                  onChange={(e) => {
                    setInvoice({ ...invoice, includeInvoiceFile: e.target.checked });
                  }}
                  name="hasShippingAddress"
                  id="hasShippingAddress"
                />
                <label htmlFor="hasShippingAddress" className="inline-flex text-primary text-base text-sm">
                  {t("invoices.editor.include")}&nbsp;
                  <a className="underline" href={invoice?.file} target="_blank" rel="noreferrer">
                    {t("invoices.editor.invoice_file")}
                  </a>
                </label>
              </div>
            </div>
          ) : (
            <div className="text-sm text-red-500">{t("invoices.editor.no_invoice_file_error")}</div>
          )}
        </div>
        {files.length > 0 &&
          files.map((file) => (
            <div className="flex items-center gap-3 ">
              <p className="text-sm italic" key={file}>
                <img src={file} className="w-4 h-4 inline-block mr-2" alt="file" />
                {file.split("/").pop()}
              </p>
              <button onClick={() => setFiles((prev) => prev.filter((f) => f !== file))}>
                <IoCloseCircleSharp />
              </button>
            </div>
          ))}
        <div className="flex flex-col items-start">
          <div className="flex flex-row items-center w-full">
            <FileInput
              onChange={async (e) => {
                console.log("✌️  file", e.target.value);
                if (!e.target.value) return;
                toast.success(t("toast.sucess.created"));
                setFiles([...files, e.target.value]);
              }}
              name={invoice._id}
              folder={`/invoice/${invoice._id}/attachments`}
              width="w-full"
            />
          </div>
        </div>
        <div className="flex justify-between mt-4 gap-6">
          <button type="button" onClick={onClose} className="btn-secondary">
            {t("cancel")}
          </button>
          <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_send_invoice)}>
            {t("invoices.editor.send_invoice")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

const File = ({ invoice }) => {
  const { t } = useTranslation();

  if (!invoice) return null;
  if (!invoice.file) return <span> {t("invoices.file.no_file")} </span>;

  return (
    <object data={invoice.file} type="application/pdf" width="100%" height="900px">
      <p>
        Texte alternatif - incluez un lien <a href="{{link}}">vers le PDF</a>
      </p>
    </object>
  );
};

export default Viewer;
