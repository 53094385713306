import { useTranslation } from "react-i18next";

const Categories = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto">
      <div className="flex flex-col bg-white rounded-xl p-6">
        <h1 className="text-2xl font-bold mb-4">{t("categories")}</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">ID</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("name")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("group")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("description")}</th>
              </tr>
            </thead>
            <tbody>
              {t("category_list", { returnObjects: true }).map((category) => (
                <tr key={category.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-4 py-3 bg-gray-100 text-gray-800 text-sm border-b">{category.id}</td>
                  <td className="px-4 py-3 text-gray-900 font-semibold text-sm border-b">{category.name}</td>
                  <td className="px-4 py-3 text-gray-800 text-sm font-medium border-b">{category.group}</td>
                  <td className="px-4 py-3 text-gray-700 text-sm border-b">{category.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Categories;
