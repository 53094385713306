import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import api from "@services/api";
import { FaInfoCircle } from "react-icons/fa";
import { Combobox } from "@components/utils/Combobox";
import CsvDownloadButton from "react-json-to-csv";
import { Listbox, Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import Loader from "@components/utils/Loader";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { FaChevronDown } from "react-icons/fa6";

const years = Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => new Date().getFullYear() - i);

const Tax = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState();
  const [filter, setFilter] = useState({
    currency: "EUR",
    by: "month",
    to: new Date(),
    since: new Date(new Date().setMonth(new Date().getMonth() - 4)),
  });
  const organization = useSelector((state) => state.Auth.organization);

  useEffect(() => {
    if (organization && filter) {
      getTaxInfos();
    }
  }, [organization, filter]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_tax);
  }, []);

  const getTaxInfos = async () => {
    try {
      setLoading(true);
      const { data } = await api.post("/analytics/tax", { ...filter, OrganizationId: organization._id });
      setTax(data.taxTypeData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetToDate = (e) => {
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth() + 1);
    newDate.setUTCDate(0);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, to: newDate }));
  };
  const handleSetSinceDate = (e) => {
    const newDate = new Date(e.target.value);
    newDate.setUTCMonth(newDate.getMonth());
    newDate.setUTCDate(1);
    newDate.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, since: newDate }));
  };
  const handleChangeDateFormat = (checked) => {
    if (!checked) {
      const currentDate = new Date();
      currentDate.setUTCMonth(currentDate.getMonth() + 1);
      currentDate.setUTCDate(0);
      currentDate.setUTCHours(0, 0, 0, 0);

      const fromDate = new Date();
      fromDate.setUTCMonth(fromDate.getMonth() - 4);
      fromDate.setUTCDate(1);
      fromDate.setUTCHours(0, 0, 0, 0);

      return setFilter({ ...filter, by: "month", to: currentDate, since: fromDate });
    }
    const since = new Date("2023-01");
    since.setUTCMonth(since.getMonth());
    since.setUTCDate(1);
    since.setUTCHours(0, 0, 0, 0);

    const to = new Date("2024-12");
    to.setUTCMonth(to.getMonth() + 1);
    to.setUTCDate(0);
    to.setUTCHours(0, 0, 0, 0);
    setFilter((date) => ({ ...date, by: "year", since: since, to: to }));
  };

  return (
    <div className="flex flex-col gap-6 items-start text-left text-black-100 ">
      <div className="flex justify-between w-full">
        <div className="text-blue text-sm flex gap-1 items-center">
          <FaInfoCircle />
          {t("beta_version")}
        </div>
      </div>
      <div className="bg-white border border-gray-300 rounded-xl p-2 w-5/12">
        <div className="gap-8 flex items-center justify-between">
          <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
            <button
              onClick={() => handleChangeDateFormat(false)}
              className={`px-3 py-1 rounded-md text-xs transition-colors ${
                filter?.by === "month" ? "bg-white text-blue-300 font-bold shadow-sm" : "text-gray-600 hover:bg-gray-200"
              }`}>
              {t("month")}
            </button>
            <button
              onClick={() => handleChangeDateFormat(true)}
              className={`px-3 py-1 rounded-md text-xs transition-colors ${
                filter?.by === "year" ? "bg-white text-blue-300 font-bold shadow-sm" : "text-gray-600 hover:bg-gray-200"
              }`}>
              {t("year")}
            </button>
          </div>

          {filter?.by === "month" ? (
            <div className="flex items-center justify-center gap-3">
              <input
                className="rounded-lg border border-gray-300 px-3 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs"
                type={filter?.by}
                value={filter?.since.toISOString().slice(0, 7)}
                onChange={handleSetSinceDate}
              />
              <span className="text-gray-400">-</span>
              <input
                className="rounded-lg border border-gray-300 px-3 py-1 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-xs"
                type={filter?.by}
                value={filter?.to.toISOString().slice(0, 7)}
                onChange={handleSetToDate}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center gap-3">
              <Listbox value={filter?.since.getFullYear()} onChange={(value) => handleSetSinceDate({ target: { value: `${value}-12` } })}>
                <div className="relative">
                  <div className="w-32 flex items-center justify-between bg-white border border-gray-300 rounded-lg px-3 py-1 cursor-pointer hover:border-blue-500">
                    <Listbox.Button className="w-full text-left text-xs">{filter?.since.getFullYear()}</Listbox.Button>
                    <FaChevronDown className="text-gray-400" />
                  </div>
                  <Listbox.Options className="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                    {years.map((year) => (
                      <Listbox.Option className="px-3 py-2 cursor-pointer hover:bg-gray-100 transition-colors text-xs" key={year} value={year}>
                        {year}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
              <span className="text-gray-400">-</span>
              <Listbox value={filter?.to.getFullYear()} onChange={(value) => handleSetToDate({ target: { value: `${value}-12` } })}>
                <div className="relative">
                  <div className="w-32 flex items-center justify-between bg-white border border-gray-300 rounded-lg px-3 py-1 cursor-pointer hover:border-blue-500">
                    <Listbox.Button className="w-full text-left text-xs">{filter?.to.getFullYear()}</Listbox.Button>
                    <FaChevronDown className="text-gray-400" />
                  </div>
                  <Listbox.Options className="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                    {years.map((year) => (
                      <Listbox.Option className="px-3 py-2 cursor-pointer hover:bg-gray-100 transition-colors text-xs" key={year} value={year}>
                        {year}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>
          )}

          {/* <div className="w-50">
            <Combobox
              id="currency"
              options={["EUR", "USD", "GBP", "CHF", "CAD", "AUD", "YEN", "JPY", "NZD", "SEK", "CNH", "PLN", "AED"]}
              value={filter?.currency}
              onChange={(value) => setFilter({ ...filter, currency: value })}
              placeholder={t("myintegrations.currency_placeholder")}
              nullable={false}
              inputClassName="py-1 text-xs"
              className="w-full"
            />
          </div> */}
        </div>
      </div>
      <Global tax={tax} loading={loading} filter={filter} />
      <Details tax={tax} loading={loading} filter={filter} />
    </div>
  );
};

const Global = ({ tax, loading, filter }) => {
  const { t } = useTranslation();

  if (loading)
    return (
      <div className="w-full p-4 bg-white border border-gray-300 rounded-xl shadow-md">
        <Loader />
      </div>
    );

  return (
    <div className="flex flex-col gap-4 w-full p-4 bg-white border border-gray-300 rounded-xl shadow-md">
      <div className="w-full flex justify-between">
        <div>
          <div className="font-semibold">
            {t("global_info")} ({t(`invoices.status.PAID`)})
          </div>
          <div className="text-xs text-gray-500">
            {filter.since.toLocaleString("fr-FR", { year: "numeric", month: "long" })} - {filter.to.toLocaleString("fr-FR", { year: "numeric", month: "long" })}
          </div>
        </div>
        {tax && (
          <CsvDownloadButton
            className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue w-40"
            data={tax.map((item) => ({
              "Tax Category": item.taxCategory,
              "Tax Type": item.taxType,
              "Tax Rate": `${item.taxRate ? `${item.taxRate} %` : "-"}`,
              "Total Tax Included": item.totalTaxIncluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency }),
              "Total Tax Excluded": item.totalTaxExcluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency }),
              "Total Tax Value": item.totalTaxValue?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency }),
            }))}
            header={["Tax Category", "Tax Type", "Tax Rate", "Total Tax Included", "Total Tax Value"]}
            filename={"tax_finotor.csv"}>
            {t("export_to_csv")}
          </CsvDownloadButton>
        )}
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white rounded-xl overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2 text-left">{t("tax_name")}</th>
              <th className="p-2 text-right">{t("VAT_rate")}</th>
              <th className="p-2 w-56 text-right">{t("tax_ttc")}</th>
              <th className="p-2 w-56 text-right">{t("tax_ht")}</th>
              <th className="p-2 w-56 text-right">{t("tax_value")}</th>
            </tr>
          </thead>
          <tbody>
            {tax?.map((taxItem, index) => (
              <tr key={`${taxItem.taxType + " - " + index}`} className="border-b-2 font-semibold">
                <td className="p-2">
                  <div className="flex flex-col gap-2">
                    <div className="text-xs text-gray-700 py-1">{t(taxItem.taxCategory)}</div>
                    <div>{taxItem.taxType}</div>
                  </div>
                </td>
                <td className="p-2 text-right align-bottom">{taxItem.taxRate ? `${taxItem.taxRate} %` : "-"} </td>
                <td className="p-2 text-right align-bottom">{taxItem.totalTaxIncluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                <td className="p-2 text-right align-bottom">{taxItem.totalTaxExcluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                <td className="p-2 text-right align-bottom">{taxItem.totalTaxValue?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Details = ({ tax, loading, filter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading)
    return (
      <div className="w-full p-4 bg-white border border-gray-300 rounded-xl shadow-md">
        <Loader />
      </div>
    );

  return (
    <div className="flex flex-col items-end gap-4 w-full p-4 bg-white border border-gray-300 rounded-xl shadow-md">
      <div className="w-full flex justify-between">
        <div>
          <div className="font-semibold">
            {t("details")} ({t(`invoices.status.PAID`)})
          </div>
          <div className="text-xs text-gray-500">
            {filter.since.toLocaleString("fr-FR", { year: "numeric", month: "long" })} - {filter.to.toLocaleString("fr-FR", { year: "numeric", month: "long" })}
          </div>
        </div>
        {tax && (
          <CsvDownloadButton
            className="btn-primary btn-small hover:bg-gray-700 focus:bg-blue w-40"
            data={tax.flatMap((item) =>
              item.invoices.map((invoice) => ({
                "Tax Category": item.taxCategory,
                "Tax Type": item.taxType,
                "Tax Rate": `${item.taxRate || invoice.taxType} %`,
                "Invoice Name": invoice.name,
                "Tax Included": invoice.taxIncluded,
                "Tax Value": invoice.taxValue,
              })),
            )}
            filename={"tax_finotor.csv"}>
            {t("export_to_csv")}
          </CsvDownloadButton>
        )}
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white rounded-xl overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2 text-left">{t("tax_name")}</th>
              <th className="p-2 text-right">{t("VAT_rate")}</th>
              <th className="p-2 w-56 text-right">{t("tax_ttc")}</th>
              <th className="p-2 w-56 text-right">{t("tax_ht")}</th>
              <th className="p-2 w-56 text-right">{t("tax_value")}</th>
            </tr>
          </thead>
          <tbody>
            {tax?.map((taxItem, index) => (
              <React.Fragment key={`${taxItem.taxType + " - details - " + index}`}>
                <tr className="border-b-2 font-semibold">
                  <td className="p-2">
                    <div className="flex flex-col gap-2">
                      <div className="text-xs text-gray-700 py-1">{t(taxItem.taxCategory)}</div>
                      <div>{taxItem.taxType}</div>
                    </div>
                  </td>
                  <td className="p-2 text-right align-bottom">{taxItem.taxRate ? `${taxItem.taxRate} %` : "-"} </td>
                  <td className="p-2 text-right align-bottom">{taxItem.totalTaxIncluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>{" "}
                  <td className="p-2 text-right align-bottom">{taxItem.totalTaxExcluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                  <td className="p-2 text-right align-bottom">{taxItem.totalTaxValue?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                </tr>
                {taxItem.invoices.map((invoice) => (
                  <tr key={`${invoice._id + " - " + index}`} className=" border-y-[1px]">
                    <td className="p-2 pl-3">
                      •{" "}
                      <span
                        onClick={() => {
                          if (taxItem.taxCategory === "sales") return navigate(`/invoices/${invoice._id}`);
                          return navigate(`/purchase-invoice?id=${invoice._id}`);
                        }}
                        className="cursor-pointer underline text-blue-500 text-sm">
                        {invoice.name}
                      </span>
                    </td>
                    <td className="p-2 text-right text-sm">{taxItem.taxType === "N/A" ? `${invoice.taxType} %` : ""}</td>
                    <td className="p-2 text-right text-sm">{invoice.taxIncluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                    <td className="p-2 text-right text-sm">{invoice.taxExcluded?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                    <td className="p-2 text-right text-sm">{invoice.taxValue?.toLocaleString("fr-FR", { style: "currency", currency: filter?.currency })}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Tax;
