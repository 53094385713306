import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MyOrganizations from "./Organizations";
import MyUsers from "./Users";
import MyProfile from "./myProfile";
import ApiKey from "./ApiKey";
import Categories from "./categories";
import { Mixpanel } from "../../services/mixpanel";
import { classNames } from "@utils";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const MY_PROFILE = "my-profile";
const MY_ORGANIZATIONS = "my-organizations";
const USERS = "users";
const CATEGORIES = "categories";
const tabList = [USERS, MY_ORGANIZATIONS, CATEGORIES];
const API_KEY = "api-key";

const View = () => {
  const { t } = useTranslation();
  const { tab: initialTab = MY_ORGANIZATIONS } = useParams();
  const [tab, setTab] = useState(tabList.includes(initialTab) ? initialTab : MY_PROFILE);
  const navigate = useNavigate();

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_organizations);
  }, []);

  useEffect(() => {
    navigate(`/settings/${tab}`);
  }, [tab]);

  return (
    <div className="container min-h-full mx-auto flex flex-col py-6 px-6 min-w-full ">
      <div className="bg-white rounded-xl px-6 py-6">
        <h2 className="font-bold mb-2">{t("settings")} </h2>
        <nav className="flex flex-row w-full overflow-hidden justify-start items-center border-b border-gray-200">
          <TabItem active={tab === MY_PROFILE} title={t("my_profile")} setTab={setTab} tab={MY_PROFILE} />
          <TabItem active={tab === MY_ORGANIZATIONS} title={t("my_organization")} setTab={setTab} tab={MY_ORGANIZATIONS} />
          <TabItem active={tab === USERS} title={t("users")} setTab={setTab} tab={USERS} />
          <TabItem active={tab === CATEGORIES} title={t("categories")} setTab={setTab} tab={CATEGORIES} />
          <TabItem active={tab === API_KEY} title={"API"} setTab={setTab} tab={API_KEY} />
        </nav>
        <div className="w-full">
          {tab === MY_PROFILE && <MyProfile />}
          {tab === MY_ORGANIZATIONS && <MyOrganizations />}
          {tab === USERS && <MyUsers />}
          {tab === CATEGORIES && <Categories />}
          {tab === API_KEY && <ApiKey />}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-black-100 !border-black" : "",
    )}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default View;
