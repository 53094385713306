import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import categories from "@data/categories.js";
import MultipleSelectInput from "../../components/utils/MultipleSelectInput";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { IoAddOutline } from "react-icons/io5";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { RxCross1 } from "react-icons/rx";

const Edit = ({ client, setClient, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    setValues({ ...client });
  }, [client]);

  if (!client || !values) return <div />;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!values.entityType) return toast.error(t("clients.edit.entity_type_required"));
      setLoading(true);
      const { ok, code, data } = await api.put(`/client/${client._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setClient(null);
      onSave(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <Modal isOpen={true} onClose={() => setClient(null)} innerClassName="flex max-w-[60rem] w-[90vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("clients.edit")}</h2>
          <button type="button" onClick={() => setClient(null)}>
            <RxCross1 />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6 overflow-auto">
          <div className="mb-6">
            <div className="text-xs text-gray-500 pb-1"> {t("type")} </div>
            <div className="flex space-x-4">
              <button
                type="button"
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
                  values.entityType === "INDIVIDUAL" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
                onClick={() => setValues({ ...values, entityType: "INDIVIDUAL" })}>
                {t("individual")}
              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
                  values.entityType === "COMPANY" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
                onClick={() => setValues({ ...values, entityType: "COMPANY" })}>
                {t("company")}
              </button>
            </div>
          </div>
          {values.entityType === "INDIVIDUAL" ? (
            <Individual client={client} values={values} setValues={setValues} />
          ) : (
            <Company client={client} values={values} setValues={setValues} />
          )}
          <div className="flex justify-end gap-2 mt-6">
            <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_client)}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const isShippingAddressDefined = (values) => {
  return values?.shippingAddressStreet || values?.shippingAddressCity || values?.shippingAddressZipCode || values?.shippingAddressCountry ? true : false;
};

const ShippingAddress = ({ client, values, setValues }) => {
  const { t } = useTranslation();
  const [hasShippingAddress, setHasShippingAddress] = useState();

  useEffect(() => {
    setHasShippingAddress(isShippingAddressDefined(client));
  }, [client]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={hasShippingAddress ?? false}
          onChange={(e) => {
            setHasShippingAddress(e.target.checked);
            if (!e.target.checked) {
              setValues({ ...values, shippingAddressStreet: null, shippingAddressCity: null, shippingAddressZipCode: null, shippingAddressCountry: null });
            }
          }}
          id="hasShippingAddress"
        />
        <label htmlFor="hasShippingAddress" className="text-gray-800 select-none">
          {t("shipping_address_differs")}
        </label>
      </div>

      {hasShippingAddress && (
        <>
          <h3 className="text-lg font-semibold text-gray-800">{t("shipping_address")}</h3>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("address")}</div>
              <input
                type="text"
                value={values?.shippingAddressStreet ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressStreet: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder="Ex. 8 Rue des Fleurs"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("zip_code")}</div>
              <input
                type="text"
                value={values?.shippingAddressZipCode ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressZipCode: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder="Ex. 60200"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("city")}</div>
              <input
                type="text"
                value={values?.shippingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressCity: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder="Ex. Compiègne"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("country")}</div>
              <input
                type="text"
                value={values?.shippingAddressCountry ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressCountry: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder="Ex. France"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const ContactFields = ({ values, setValues }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      {(values.contacts || [])?.map((contact, index) => (
        <div key={index}>
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("last_name")}</div>
              <input
                type="text"
                value={contact.lastname}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].lastname = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("last_name")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("first_name")}</div>
              <input
                type="text"
                value={contact.firstname}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].firstname = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("first_name")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("email")}</div>
              <input
                type="text"
                value={contact.email}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].email = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("clients.edit.email_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("clients.edit.landline_number")}</div>
              <input
                type="text"
                value={contact.landlinePhone}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].landlinePhone = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("clients.edit.landline_number_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("clients.edit.mobile_number")}</div>
              <input
                type="text"
                value={contact.mobilePhone}
                onChange={(e) => {
                  const updatedContacts = [...values.contacts];
                  updatedContacts[index].mobilePhone = e.target.value;
                  setValues({ ...values, contacts: updatedContacts });
                }}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("clients.edit.mobile_number_placeholder")}
              />
            </div>
          </div>
          <div className="border-t border-t-gray-300 mt-6 mb-6"></div>
        </div>
      ))}
      <button
        type="button"
        onClick={() => setValues({ ...values, contacts: [...(values.contacts || []), { firstname: "", lastname: "", email: "" }] })}
        className="btn-secondary flex gap-x-2 items-center w-fit">
        <IoAddOutline /> {t("new_contact")}
      </button>
    </div>
  );
};

const Individual = ({ client, values, setValues }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("first_name")}</div>
          <input
            required
            type="text"
            value={values.firstname || ""}
            onChange={(e) => setValues({ ...values, firstname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.first_name_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("last_name")}</div>
          <input
            required
            type="text"
            value={values.lastname || ""}
            onChange={(e) => setValues({ ...values, lastname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.last_name_placeholder")}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("clients.edit.recipient_emails")}</div>
        <MultipleSelectInput
          value={values.emails ?? []}
          placeholder={t("clients.edit.recipient_emails_placeholder")}
          onChange={(e) => setValues({ ...values, emails: e.map((e) => e.trim()) })}
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("clients.edit.landline_number")}</div>
          <input
            type="text"
            value={values.landlinePhone ?? ""}
            onChange={(e) => setValues({ ...values, landlinePhone: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.landline_number_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("clients.edit.mobile_number")}</div>
          <input
            type="text"
            value={values.mobilePhone ?? ""}
            onChange={(e) => setValues({ ...values, mobilePhone: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.mobile_number_placeholder")}
          />
        </div>
      </div>

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("contacts")}</h2>
      <ContactFields values={values} setValues={setValues} />

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("billing_address")}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("street")}</div>
          <input
            type="text"
            value={values.billingAddressStreet ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.street_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("city")}</div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.city_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("zip_code")}</div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.zip_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("clients.edit.country_placeholder")}</div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.country_placeholder")}
          />
        </div>
      </div>

      <ShippingAddress client={client} values={values} setValues={setValues} />

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("clients.edit.client_activity")}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("default_category")}</div>
          <Combobox
            value={categories.find((category) => category.id == values.defaultCategory)}
            options={categories}
            onChange={(value) => setValues({ ...values, defaultCategory: value.id || null })}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            placeholder={t("select_category")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("website")}</div>
          <input
            type="text"
            value={values.website ?? ""}
            onChange={(e) => setValues({ ...values, website: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.edit.website_placeholder")}
          />
        </div>
      </div>

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("memo_note")}</div>
        <textarea
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder={t("memo_note.to_enter.placeholder")}
        />
      </div>
    </div>
  );
};

const Company = ({ client, values, setValues }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("company_name")}</div>
          <input
            required
            type="text"
            value={values.companyName || ""}
            onChange={(e) => setValues({ ...values, companyName: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("clients.company_name_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("business_registration_number")}</div>
          <input
            type="text"
            value={values.registrationNumber || ""}
            onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Ex. SIREN"
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("vat_number")}</div>
        <input
          type="text"
          value={values.vatNumber ?? ""}
          onChange={(e) => setValues({ ...values, vatNumber: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder="Ex. FR12345678901"
        />
      </div>

      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("clients.edit.recipient_emails")}</div>
        <MultipleSelectInput
          value={values.emails ?? []}
          placeholder={t("clients.edit.recipient_emails_placeholder")}
          onChange={(e) => setValues({ ...values, emails: e.map((e) => e.trim()) })}
        />
      </div>

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("contacts")}</h2>
      <ContactFields values={values} setValues={setValues} />

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("billing_address")}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("street")}</div>
          <input
            type="text"
            value={values.billingAddressStreet ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Ex. 12 Rue de la Paix"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("city")}</div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Ex. Paris"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("zip_code")}</div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Ex. 75008"
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("country")}</div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Ex. France"
          />
        </div>
      </div>

      <ShippingAddress client={client} values={values} setValues={setValues} />

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold mb-2">{t("clients.edit.client_activity")}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("default_category")}</div>
          <Combobox
            value={categories.find((category) => category.id == values.defaultCategory)}
            options={categories}
            onChange={(value) => setValues({ ...values, defaultCategory: value.id || null })}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            placeholder={t("select_category")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("website")}</div>
          <input
            type="text"
            value={values.website ?? ""}
            onChange={(e) => setValues({ ...values, website: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="www.example.com"
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("memo_note")}</div>
        <textarea
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder="Ex. Informations complémentaires sur l'entreprise"
        />
      </div>
    </div>
  );
};

export default Edit;
