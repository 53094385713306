import { useEffect, useState } from "react";
import api from "@services/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setOrganization } from "@redux/auth/actions";
import { useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../components/utils/Loader";
import { Mixpanel } from "../../services/mixpanel";
import { STRIPE_CHECKOUT_LINK_BUSINESS, STRIPE_CHECKOUT_LINK_LITE } from "../../config";
import { FaArrowRight, FaCheckCircle, FaSignOutAlt } from "react-icons/fa";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import toast from "react-hot-toast";

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, organization } = useSelector((state) => state.Auth);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await api.get("/user/signin_token");
      if (!res.ok || !res.user) return setLoading(false);
      if (res.token) api.setToken(res.token);
      dispatch(setUser(res.user));
      Mixpanel.identify(res.user._id);
      Mixpanel.people.set({ ...res.user, $email: res.user.email });
      i18n.changeLanguage(res.user.language);
      Mixpanel.track(MIXPANEL_EVENTS.loadSuccess);
      const { data: organizations } = await api.post("/organization/search");
      dispatch(setOrganization(organizations[0]));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function logout() {
    await api.post(`/user/logout`);
    dispatch(setUser(null));
    navigate("/auth");
  }

  const updateOrganizationStarterPlan = async () => {
    try {
      setLoading(true);
      const { ok, data } = await api.put(`/organization/${organization._id}`, { plan: "starter" });
      if (!ok || !data) {
        toast.error(t("error_occurred"));
        return;
      }
      Mixpanel.track(MIXPANEL_EVENTS.btn_sub_0);
      dispatch(setOrganization(data));
      toast.success(t("checkout.starter_plan.activated"));
      navigate("/");
    } catch (e) {
      console.log(e);
      toast.error(t("error_occurred"));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50">
        <Loader />
      </div>
    );
  }

  if (!user) return <Navigate to="/auth" replace />;
  if (organization && ["premium", "starter", "lite", "business"].includes(organization.plan)) return <Navigate to="/" replace />;

  const remainingDays = Math.ceil((new Date(organization?.freeTrialEndAt) - new Date()) / (1000 * 60 * 60 * 24));
  const freeTrialIsEnded = organization?.plan === "free" && organization?.freeTrialEndAt && new Date(organization?.freeTrialEndAt) < new Date();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-8 px-4">
      {/* Header Message */}
      <div className="pb-6 w-full max-w-5xl text-center">
        {freeTrialIsEnded ? (
          <p className="text-blue-600 text-lg font-medium">{t("free_trial_ended")}</p>
        ) : (
          <p className="text-green-600 text-lg font-medium">
            <b>{remainingDays}</b> {t("free_trial_remaining")}
          </p>
        )}
      </div>

      {/* Logout Button */}
      <button className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 pr-4 pt-4 flex items-center" onClick={logout} aria-label="Logout">
        <FaSignOutAlt className="mr-1" />
        {t("logout")}
      </button>

      {/* Plans Section */}
      <div className="flex flex-col md:flex-row gap-8 justify-center w-full max-w-7xl">
        {/* Starter Plan */}
        <PlanCard
          title={t("checkout.starter_title")}
          price="0€"
          onClick={updateOrganizationStarterPlan}
          features={[
            t("checkout.one_user"),
            t("checkout.create_unlimited_invoices"),
            t("checkout.create_unlimited_clients"),
            t("checkout.create_unlimited_products"),
            t("checkout.create_unlimited_suppliers"),
            t("checkout.create_unlimited_expenses"),
            t("checkout.connect_bank"),
            t("checkout.cash_flow_management"),
          ]}
          isPopular={false}
        />
        {/* Lite Plan */}
        <PlanCard
          title={t("checkout.lite_title")}
          price="19€"
          link={`${STRIPE_CHECKOUT_LINK_LITE}?prefilled_email=${user.email}&client_reference_id=${organization._id}`}
          onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_sub_19)}
          features={[
            t("checkout.unlimited_users"),
            t("checkout.create_unlimited_invoices"),
            t("checkout.create_unlimited_clients"),
            t("checkout.create_unlimited_products"),
            t("checkout.create_unlimited_suppliers"),
            t("checkout.create_unlimited_expenses"),
            t("checkout.connect_bank"),
            t("checkout.cash_flow_management"),
            t("checkout.connect_stripe_paypal"),
            t("checkout.ai_integration"),
          ]}
          isPopular={true}
        />
        {/* Business Plan */}
        <PlanCard
          title={t("checkout.business_title")}
          price="99€"
          link={`${STRIPE_CHECKOUT_LINK_BUSINESS}?prefilled_email=${user.email}&client_reference_id=${organization._id}`}
          onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_sub_99)}
          features={[
            t("checkout.unlimited_users"),
            t("checkout.create_unlimited_invoices"),
            t("checkout.create_unlimited_clients"),
            t("checkout.create_unlimited_products"),
            t("checkout.create_unlimited_suppliers"),
            t("checkout.create_unlimited_expenses"),
            t("checkout.connect_bank"),
            t("checkout.cash_flow_management"),
            t("checkout.connect_stripe_paypal"),
            t("checkout.ai_integration"),
            t("checkout.ocr"),
            t("checkout.categorize"),
            t("checkout.balance_sheet"),
            t("checkout.profit_loss"),
            t("checkout.accountant_access"),
            t("checkout.dedicated_onboarding"),
          ]}
          isPopular={false}
        />
      </div>
    </div>
  );
};

const PlanCard = ({ title, price, link, onClick, features, isPopular }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`w-full max-w-lg bg-white rounded-xl shadow-lg p-8 flex flex-col transform transition-transform hover:scale-105 ${isPopular ? "border-2 border-blue-600" : ""}`}>
      {/* Popular Badge */}
      {isPopular && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white text-xs font-semibold uppercase px-3 py-1 rounded-full shadow-md">
          {t("checkout.most_popular")}
        </div>
      )}
      <div className="flex flex-col items-center">
        <img src="/favicon.png" alt="Logo" className="w-14 h-14 mb-4" />
        <h2 className="text-2xl font-bold text-gray-800">{title}</h2>
        <div className="flex items-baseline pt-4">
          <span className="text-4xl font-extrabold text-gray-800">{price}</span>
          <span className="text-lg text-gray-500 ml-2">/{t("checkout.per_month")}</span>
        </div>
        <a href={link} target="_blank" rel="noreferrer" className="w-full">
          <button
            onClick={onClick}
            className="mt-6 w-full bg-blue-600 text-white py-3 px-6 rounded-lg flex items-center justify-center hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label={`Start ${title} plan`}>
            {t("checkout.start")}
            <FaArrowRight className="ml-2" />
          </button>
        </a>
      </div>
      <div className="pt-6">
        <h3 className="text-gray-700 font-semibold mb-4">{t("checkout.include")}:</h3>
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start text-gray-600">
              <FaCheckCircle className="text-green-500 mt-1 mr-2" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Checkout;
