export const MIXPANEL_EVENTS = {
  loadSuccess: "SUCCESSFULLY_LOADED",

  btn_create_client: "btn_create_client",
  btn_create_employee: "btn_create_employee",
  btn_create_product: "btn_create_product",
  btn_create_supplier: "btn_create_supplier",
  btn_create_invoice: "btn_create_invoice",
  btn_create_purchase_invoice: "btn_create_purchase_invoice",

  btn_open_client_create_modal: "btn_open_client_create_modal",
  btn_open_employee_create_modal: "btn_open_employee_create_modal",
  btn_open_product_create_modal: "btn_open_product_create_modal",
  btn_open_create_petty_cash: "btn_open_create_petty_cash",
  btn_open_purchase_invoice_create_modal: "btn_open_purchase_invoice_create_modal",
  btn_open_organization_invite_modal: "btn_open_organization_invite_modal",

  btn_save_client: "btn_save_client",
  btn_save_employee: "btn_save_employee",
  btn_save_invoice: "btn_save_invoice",
  btn_save_product: "btn_save_product",
  btn_save_purchase_invoice: "btn_save_purchase_invoice",
  btn_save_supplier: "btn_save_supplier",

  btn_transaction_created: "btn_transaction_created",
  btn_transaction_updated: "btn_transaction_updated",
  btn_transaction_reconciled: "btn_transaction_reconciled",
  btn_transaction_created_reconciled: "btn_transaction_created_reconciled",
  btn_transaction_cancel_reconciled: "btn_transaction_cancel_reconciled",
  btn_reconcile_transactions: "btn_reconcile_transactions",

  btn_subscription: "btn_subscription",
  btn_sub_0: "btn_sub_0",
  btn_sub_19: "btn_sub_19",
  btn_sub_49: "btn_sub_49",
  btn_sub_99: "btn_sub_99",

  btn_connect_xero: "btn_connect_xero",
  "btn_skip_xero-contacts": "btn_skip_xero-contacts",
  "btn_skip_xero-invoices": "btn_skip_xero-invoices",
  "btn_skip_xero-invoices-purchase": "btn_skip_xero-invoices-purchase",
  "btn_import_xero-contacts": "btn_import_xero-contacts",
  "btn_import_xero-invoices": "btn_import_xero-invoices",
  "btn_import_xero-products": "btn_import_xero-products",
  "btn_import_xero-invoices-purchase": "btn_import_xero-invoices-purchase",

  btn_invite_user: "btn_invite_user",

  btn_renew_account_requisition: "btn_renew_account_requisition",

  btn_finished_invoice: "btn_finished_invoice",
  btn_send_invoice: "btn_send_invoice",
  btn_add_payment_link_invoice: "btn_add_payment_link_invoice",
  btn_connect_gocardless_invoice: "btn_connect_gocardless_invoice",
  btn_action_required_gocardless_invoice: "btn_action_required_gocardless_invoice",
  btn_generate_payment_link_gocardless_invoice: "btn_generate_payment_link_gocardless_invoice",
  btn_copy_referral_link: "btn_copy_referral_link",
  btn_review_truspilot: "btn_review_truspilot",

  page_view_home: "page_view_home",
  page_view_analytics: "page_view_analytics",
  page_view_analytics_accounting: "page_view_analytics_accounting",
  page_view_analytics_profit_loss: "page_view_analytics_profit_loss",
  page_view_analytics_tax: "page_view_analytics_tax",
  page_view_analytics_ledger: "page_view_analytics_ledger",
  page_view_analytics_payment_links: "page_view_analytics_payment_links",
  page_view_signin: "page_view_signin",
  page_view_signup: "page_view_signup",
  page_view_bank: "page_view_bank",
  page_view_bank_add: "page_view_bank_add",
  page_view_clients: "page_view_clients",
  page_view_clients_edit: "page_view_clients_edit",
  page_view_employees: "page_view_employees",
  page_view_employees_edit: "page_view_employees_edit",
  page_view_invoices: "page_view_invoices",
  page_view_invoice_editor: "page_view_invoice_editor",
  page_view_products: "page_view_products",
  page_view_products_edit: "page_view_products_edit",
  "page_view_purchase-invoices": "page_view_purchase-invoices",
  page_view_purchase_invoice_edit: "page_view_purchase_invoice_edit",
  page_view_organizations: "page_view_organizations",
  page_view_profile: "page_view_profile",
  page_view_migration_xero: "page_view_migration_xero",
  "page_view_transactions-stripe": "page_view_transactions-stripe",
  page_view_suppliers: "page_view_suppliers",
  page_view_suppliers_edit: "page_view_suppliers_edit",
  page_view_transactions: "page_view_transactions",
  page_view_referral_page: "page_view_referral_page",
};
