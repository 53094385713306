import Loader from "@components/utils/Loader";
import api from "@services/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TbClockBolt } from "react-icons/tb";
import { MdBolt } from "react-icons/md";

import { statuses } from "@constants";
import { getClientName } from "@utils";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import ActionsMenu from "@components/utils/ActionsMenu";
import TruncatedText from "@components/utils/TruncatedText";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import CreateInvoice from "./createInvoice";
import Stats from "./stats";

const List = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10, statuses: statuses.filter((status) => status !== "ARCHIVED") });
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const organization = useSelector((state) => state.Auth.organization);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [organization, filter]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_invoices);
  }, []);

  async function get() {
    const query = {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      statuses: filter.statuses,
      OrganizationId: organization._id,
    };
    const { data } = await api.post("/invoice/search", query);
    console.log("✌️  data.invoices", data.invoices);
    setInvoices(data.invoices);
    setTotal(data.total);
  }

  async function handleDuplicate(id) {
    const confirm = window.confirm(t("confirm_duplicate_message"));
    if (!confirm) return;
    try {
      const duplicateReq = await api.get(`/invoice/${id}/duplicate`);
      if (!duplicateReq.ok) throw duplicateReq.code;

      const invoice = duplicateReq.data;
      // update invoice name
      const updateReq = await api.put(`/invoice/${invoice._id}`, {
        name: t("invoices.editor.subject_default", { organization: organization.name, reference: invoice.reference }),
      });
      if (!updateReq.ok) throw updateReq.code;
      toast.success(t("toast.sucess.duplicated"));
      navigate(`/invoices/${invoice._id}`);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  }
  async function handleArchive(id) {
    const confirm = window.confirm(t("confirm_message"));
    if (!confirm) return;
    await api.put(`/invoice/${id}`, { status: "ARCHIVED" });
    toast.success(t("toast.sucess.archived"));
    get();
  }

  if (!invoices) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("invoices")}</h1>
              <p className="mt-2 text-slate-600">{t("invoices.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="bnt-primary focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <CreateInvoice
                onCreated={(el) => {
                  get();
                  navigate(`/invoices/${el._id}`);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-5 mt-2">
            <Stats />
            <div className="flex items-end space-x-6">
              <SearchBar search={filter.search} setFilter={setFilter} />
              <div className="w-full md:w-80">
                <label className="text-xs text-gray-500"> {t("status")} </label>
                <Select
                  options={statuses}
                  value={filter.statuses}
                  multiple
                  getLabel={(status) => t(`invoices.status.${status}`)}
                  onChange={(statuses) => setFilter({ ...filter, statuses })}
                />
              </div>
            </div>
            <Table invoices={invoices} onEdit={(invoice) => navigate(`/invoices/${invoice._id}`)} onArchive={handleArchive} onDuplicate={handleDuplicate} />
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ invoices, onEdit, onArchive, onDuplicate }) => {
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border pb-24">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("status")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900  uppercase">
              {t("reference")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("sent_at")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("due_date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("client")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-bold text-gray-500   uppercase">
              {t("vat")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-bold text-gray-500   uppercase">
              {t("total")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {invoices.map((invoice) => (
            <tr key={invoice._id}>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div className="flex items-center gap-x-2 gap-y-1 w-fit">
                  <Status status={invoice.status} />
                  {invoice.source === "EXTERNAL" && <div className="gap-x-1.5 rounded-md text-xs text-gray-600 italic"> {t(`invoices.source.${invoice.source}`)}</div>}
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <span onClick={() => onEdit(invoice)} className="cursor-pointer underline font-bold ">
                  {(invoice.reference || "-").substring(0, 20)}
                </span>
                {Boolean(invoice.InvoiceRecurrence) && (
                  <div className="flex gap-1 items-center text-xs italic">
                    <TbClockBolt />
                    {t("invoices.list.next")}:&nbsp;{new Date(invoice.InvoiceRecurrence?.nextRunAt).toLocaleDateString().split("T")[0]}
                  </div>
                )}
                {invoice.source === "RECURRING" && (
                  <div className="flex gap-1 items-center text-xs italic">
                    <MdBolt />
                    {t("invoices.list.initial_invoice")}:&nbsp;
                    <Link className="underline" to={`/invoices/${invoice.sourceInvoiceId}`}>
                      {invoice.sourceInvoice?.reference}
                    </Link>
                  </div>
                )}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{invoice?.sentAt ? new Date(invoice.sentAt).toLocaleDateString().split("T")[0] : "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{invoice?.dueAt ? new Date(invoice.dueAt).toLocaleDateString().split("T")[0] : "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <TruncatedText str={getClientName(invoice.Client)} description={invoice.Client?.name} />
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right">{invoice?.taxValue}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right">{invoice?.totalTaxIncluded}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div className="flex justify-end w-full">
                  <ActionsMenu
                    actions={[
                      { label: t("edit"), callback: () => onEdit(invoice) },
                      { label: t("archive"), callback: () => onArchive(invoice._id) },
                      { label: t("duplicate"), callback: () => onDuplicate(invoice._id) },
                    ]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  let dotColor = "bg-gray-400";
  if (status === "ARCHIVED") {
    bgColor = "bg-orange-100";
    textColor = "text-orange-600";
    dotColor = "bg-orange-400";
  }
  if (status === "DUE") {
    bgColor = "bg-purple-100";
    textColor = "text-purple-600";
    dotColor = "bg-purple-400";
  }
  if (status === "SENT") {
    bgColor = "bg-blue-100";
    textColor = "text-blue-600";
    dotColor = "bg-blue-400";
  }
  if (status === "PAID") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
      {t(`invoices.status.${status}`)}
    </span>
  );
};

export default List;
