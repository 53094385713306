import { Combobox } from "@components/utils/Combobox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const SupplierComboBox = ({
  transaction,
  onUpdateTransaction,
  index,
  setShowCreateSupplier,
  setCreatedSupplierIndex,
  setCreatedByMultiple,
  supplierOptions,
  selectedTransactions,
  width,
}) => {
  const getSelectedSupplier = () => {
    return selectedTransactions.every((transaction) => transaction.SupplierId === selectedTransactions[0].SupplierId)
      ? supplierOptions.find((supplier) => supplier._id == selectedTransactions[0].SupplierId)?._id
      : null;
  };

  const { t } = useTranslation();
  const [selectedSupplierId, setSelectedSupplierId] = useState(selectedTransactions ? getSelectedSupplier() : transaction?.SupplierId || transaction?.SupplierIdSuggestion || null);

  useEffect(() => {
    setSelectedSupplierId(transaction?.SupplierId || transaction?.SupplierIdSuggestion || null);
  }, [transaction]);

  useEffect(() => {
    if (selectedTransactions) setSelectedSupplierId(getSelectedSupplier());
  }, [selectedTransactions]);

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.SupplierId && transaction?.SupplierIdSuggestion === item._id) return `${supplierLabel} (${t("suggested")})`;
    return supplierLabel;
  };

  const handleOnChange = (e) => {
    if (selectedTransactions) {
      onUpdateTransaction({ params: { SupplierId: e?._id || null }, needTransactionsUpdate: true });
    } else {
      onUpdateTransaction({
        params: {
          ...transaction,
          SupplierId: e?._id || null,
          category: transaction.category || e?.defaultCategory,
        },
        needTransactionsUpdate: true,
      });
      setSelectedSupplierId(e?._id || null);
    }
  };

  return (
    <div className="relative" data-tooltip-id={`tooltip-supplier-${transaction?._id}`}>
      <Combobox
        width={width}
        options={supplierOptions}
        value={supplierOptions.find((supplier) => supplier._id === selectedSupplierId)}
        onChange={async (e) => handleOnChange(e)}
        placeholder={t("supplier")}
        getLabel={(supplier) => supplierOption(supplier)}
        nullable={true}
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              selectedTransactions ? setCreatedByMultiple(true) : setCreatedSupplierIndex(index);
              setShowCreateSupplier(true);
            }}>
            {t("supplier.create_supplier")} <span>+</span>
          </button>
        }
        displayValue={(supplier) => supplierOption(supplier)}
      />
      {selectedSupplierId && !selectedTransactions && (
        <Tooltip id={`tooltip-supplier-${transaction?._id}`} style={{ zIndex: 10, borderRadius: "6px" }}>
          {supplierOption(supplierOptions.find((supplier) => supplier._id === selectedSupplierId))}
        </Tooltip>
      )}
    </div>
  );
};

export default SupplierComboBox;
