import { useState, useEffect } from "react";
import api from "@services/api";
import toast, { LoaderIcon } from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { RxCross1 } from "react-icons/rx";

const CreateClient = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_client_create_modal);
    }
    setValues({ entityType: "INDIVIDUAL" });
  }, [open]);

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[40rem] w-[90vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-8 pt-8 pb-3 border-b flex justify-between">
            <h2 className="text-2xl font-semibold text-gray-800">{t("clients.create_a_client")}</h2>
            <button type="submit" onClick={onClose}>
              <RxCross1 />
            </button>
          </div>
          <div className="p-6 overflow-auto">
            <div className="mb-6">
              <div className="text-xs text-gray-500"> {t("type")} </div>
              <div className="flex space-x-4 py-1">
                <button
                  type="button"
                  className={`flex-1 py-1 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    values.entityType === "INDIVIDUAL" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                  }`}
                  onClick={() => setValues({ ...values, entityType: "INDIVIDUAL" })}>
                  {t("individual")}
                </button>
                <button
                  type="button"
                  className={`flex-1 py-1 px-3 rounded-md transition-colors duration-200 ease-in-out ${
                    values.entityType === "COMPANY" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                  }`}
                  onClick={() => setValues({ ...values, entityType: "COMPANY" })}>
                  {t("company")}
                </button>
              </div>
            </div>
            {values.entityType === "COMPANY" ? (
              <CompanyForm values={values} setValues={setValues} onClose={onClose} onCreated={onCreated} />
            ) : (
              <IndividualForm values={values} setValues={setValues} onClose={onClose} onCreated={onCreated} />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const CompanyForm = ({ values, setValues, onClose, onCreated }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      const submittedValues = {
        entityType: "COMPANY",
        companyName: values.companyName,
      };
      const { data } = await api.post("/client", { ...submittedValues, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 grid-rows-1 gap-6">
        <div className="flex flex-col row-start-1">
          <div className="text-xs text-gray-500 pb-1"> {t("company_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.companyName}
            onChange={(e) => setValues({ ...values, companyName: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Tesla"
          />
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-6">
        {!isLoading ? (
          <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_client, { entityType: "COMPANY" })}>
            {t("clients.create_company")}
          </button>
        ) : (
          <div className="btn-primary cursor-not-allowed flex items-center gap-2">
            <LoaderIcon className="animate-spin" />
            {t("clients.create_company")}
          </div>
        )}
      </div>
    </form>
  );
};

const IndividualForm = ({ values, setValues, onClose, onCreated }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      const submittedValues = {
        entityType: "INDIVIDUAL",
        firstname: values.firstname,
        lastname: values.lastname,
      };
      const { data } = await api.post("/client", { ...submittedValues, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 grid-rows-1 gap-6">
        <div className="flex flex-col row-start-1">
          <div className="text-xs text-gray-500 pb-1"> {t("first_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.firstname}
            onChange={(e) => setValues({ ...values, firstname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Elon"
          />
        </div>
        <div className="flex flex-col row-start-1">
          <div className="text-xs text-gray-500 pb-1"> {t("last_name")} </div>
          <input
            required
            type="text"
            defaultValue={values?.lastname}
            onChange={(e) => setValues({ ...values, lastname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder="Musk"
          />
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-6">
        {!isLoading ? (
          <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_client, { entityType: "INDIVIDUAL" })}>
            {t("clients.create_client")}
          </button>
        ) : (
          <div className="btn-primary cursor-not-allowed flex items-center gap-2">
            <LoaderIcon className="animate-spin" />
            {t("clients.create_client")}
          </div>
        )}
      </div>
    </form>
  );
};
export default CreateClient;
