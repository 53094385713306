import { useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";

import { useTranslation } from "react-i18next";
import FileInput from "../../components/utils/FileInput";
import { Mixpanel } from "../../services/mixpanel";

import LoadingButton from "../../components/loadingButton";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const CreatePurchaseInvoice = ({ onCreated }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        className="btn-primary focus:bg-blue float-right"
        onClick={() => {
          setOpen(true);
          Mixpanel.track(MIXPANEL_EVENTS.btn_open_purchase_invoice_create_modal);
        }}>
        {t("purchase_invoices.create")}
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="" innerClassName="flex max-w-[50rem] w-[50vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl py-4 px-4 bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-4 text-left text-[26px] font-semibold"> {t("purchase_invoices.create")} </div>
          <div className="py-4 px-4 overflow-auto">
            <div className="flex flex-col row-start-1">
              <Form setOpen={setOpen} onCreated={onCreated} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Form = ({ setOpen, onCreated }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [loadingMessage, setLoadingMessage] = useState();
  const [values, setValues] = useState({});
  const { organization } = useSelector((state) => state.Auth);

  const handleSubmit = async (event) => {
    setLoading(true);
    setLoadingMessage(t("purchase_invoices.loading_message_create"));
    try {
      event.preventDefault();
      const { data } = await api.post("/purchase-invoice", {
        OrganizationId: organization._id,
        reference: decodeURIComponent(values.file.split("/").pop().split(".").shift()),
        file: values?.file,
      });
      setLoadingMessage(t("purchase_invoices.loading_message_ocr"));
      const responseOcr = await api.post(`/purchase-invoice/${data._id}/ocr`, { OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      setLoadingMessage("");
      setLoading(false);
      setOpen(false);
      onCreated(responseOcr.data);
      setValues();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="flex flex-col row-start-1">
        <div className="text-base mb-1 font-semibold"> {t("purchase_invoices.import")} </div>
        <FileInput value={values?.file} onChange={(e) => setValues({ ...values, file: e.target.value })} name="{transaction._id}" folder="purchaseInvoiceTransaction" />
      </div>
      <div className="flex justify-between mt-4 gap-6">
        <button type="submit" className="btn-secondary" onClick={() => setOpen(false)}>
          {t("cancel")}
        </button>
        <LoadingButton
          disabled={!values?.file}
          type="submit"
          loading={loading}
          loadingMessage={loadingMessage}
          className="btn-primary"
          onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_purchase_invoice)}>
          {t("purchase_invoices.create")}
        </LoadingButton>
      </div>
    </form>
  );
};
export default CreatePurchaseInvoice;
